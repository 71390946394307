import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import queryString from "query-string";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import emailjs from "emailjs-com";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firebaseApp } from "../index";
import { v4 as uuidv4 } from "uuid";
import "../assets/signature.css";
import Cleave from "cleave.js/react";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import sha256 from "js-sha256";

const SignatureForm = (props) => {
  const sigCanvas = useRef({});
  const [canvasWidth, setCanvasWidth] = useState(
    window.innerWidth > 768 ? 500 : 370
  );
  const formRef = useRef(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fileUploadDone, setFileUploadDone] = useState(false);
  const [stage, setStage] = useState(0);
  const [loading, setLoading] = useState(true);

  // Get Firestore instance
  const db = getFirestore();

  const parsed = queryString.parse(window.location.search);
  const {
    companyname,
    email,
    firstname,
    lastname,
    address,
    phone,
    logoURL,
    backgroundColor,
    emailFromName,
    emailFromEmail,
    emailReplyTo,
    emailSubject,
    emailGreetings,
    emailDescriptionOne,
    emailDescriptionTwo,
    downloadText,
    emailSignature,
  } = parsed;

  const [formData, setFormData] = useState({
    companyname: companyname || "",
    email: email || "",
    firstname: firstname || "",
    lastname: lastname || "",
    address: address || "",
    phone: phone || "",
    logoURL: logoURL || "",
    backgroundColor: backgroundColor || "white",
    emailFromName: emailFromName,
    emailFromEmail: emailFromEmail,
    emailReplyTo: emailReplyTo,
    emailSubject: emailSubject || "Here is a copy of your contract",
    emailGreetings: emailGreetings || "Dear",
    emailDescriptionOne: emailDescriptionOne,
    emailDescriptionTwo: emailDescriptionTwo,
    downloadText: downloadText || "Download Contract",
    emailSignature: emailSignature || "Have a great day!",
  });

  const emailHash = sha256(formData.email);

  const getEmailBasedURL = (email) => {
    return encodeURIComponent(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "fullname") {
      const lastSpaceIndex = value.lastIndexOf(" ");
      const firstname =
        lastSpaceIndex === -1 ? value : value.slice(0, lastSpaceIndex);
      const lastname =
        lastSpaceIndex === -1 ? "" : value.slice(lastSpaceIndex + 1);
      setFormData((prevState) => ({
        ...prevState,
        firstname: firstname,
        lastname: lastname,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const generatePDFUsingPdfLib = async (pdfFileName, logoDataURL) => {
    return new Promise(async (resolve) => {
      const contractArticle = document.querySelector(".contract-text");
      const text = contractArticle.innerText;

      const pdfDoc = await PDFDocument.create();
      let page = pdfDoc.addPage([595, 842]); // A4 page size in points

      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const fontSize = 11;
      const lineHeight = fontSize * 1.5;
      const textColor = rgb(0, 0, 0);
      const margin = 50;

      const pageWidth = 595 - 2 * margin;
      const maxWidth = pageWidth;

      let textOffset = margin;

      const lines = text.split("\n");
      for (const line of lines) {
        let words = line.split(" ");
        let currentLine = "";

        for (const word of words) {
          const testLine = currentLine + word + " ";
          const lineWidth = font.widthOfTextAtSize(testLine, fontSize);

          if (lineWidth > maxWidth) {
            if (textOffset + lineHeight > 842 - margin) {
              page = pdfDoc.addPage([595, 842]);
              textOffset = margin;
            }

            page.drawText(currentLine, {
              x: margin,
              y: 842 - textOffset,
              size: fontSize,
              font: font,
              color: textColor,
            });

            textOffset += lineHeight;
            currentLine = word + " ";
          } else {
            currentLine = testLine;
          }
        }

        if (textOffset + lineHeight > 842 - margin) {
          page = pdfDoc.addPage([595, 842]);
          textOffset = margin;
        }

        page.drawText(currentLine, {
          x: margin,
          y: 842 - textOffset,
          size: fontSize,
          font: font,
          color: textColor,
        });

        textOffset += lineHeight;
      }

      // Add some space after the last line of text
      textOffset += lineHeight;

      // Draw full name
      page.drawText(`Full Name: ${formData.firstname} ${formData.lastname}`, {
        x: margin,
        y: 842 - textOffset,
        size: fontSize,
        font: font,
        color: textColor,
      });
      textOffset += lineHeight;

      // Draw signature
      const signatureDataURL = sigCanvas.current.getCanvas().toDataURL();
      const signatureImage = await pdfDoc.embedPng(signatureDataURL);
      const signatureDimensions = signatureImage.scale(0.5); // Adjust scale as needed
      page.drawImage(signatureImage, {
        x: margin,
        y: 842 - textOffset - signatureDimensions.height,
        width: signatureDimensions.width,
        height: signatureDimensions.height,
      });
      textOffset += signatureDimensions.height + 10; // Add some space after the signature

      // Draw date
      const currentDate = new Date();
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(currentDate);

      page.drawText(`Signed on: ${formattedDate}`, {
        x: margin,
        y: 842 - textOffset,
        size: fontSize,
        font: font,
        color: textColor,
      });

      page.drawText(`Digital Certification: ${pdfFileName}`, {
        x: margin,
        y: 822 - textOffset,
        size: fontSize,
        font: font,
        color: textColor,
      });

      const pdfBytes = await pdfDoc.save();
      resolve(pdfBytes);
    });
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const submitSignature = async () => {
    if (!formData.intentToSign || !formData.consent) {
      alert(
        "Please confirm your intent to sign and consent to the use of electronic signatures and records."
      );
      return;
    }
    if (sigCanvas.current.isEmpty()) {
      alert("Please provide a signature.");
      return;
    }

    // Show spinner
    document.getElementById("spinner").classList.remove("d-none");
    document.getElementById("submit-text").classList.add("d-none");

    // Generate unique ID and PDF file name
    const uuid = uuidv4();
    const pdfFileName = uuid + ".pdf";

    // Reference to the Firebase Storage location
    const storageInstance = getStorage(firebaseApp);
    const clientFolder = `${formData.email}`;
    const pdfRef = ref(
      storageInstance,
      `leads/${clientFolder}/signed-contract__${formData.firstname}-${formData.lastname}__${pdfFileName}`
    );

    // Generate the PDF
    const pdfData = await generatePDFUsingPdfLib(uuid);

    // Convert the PDF data to a Blob
    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

    // Upload the PDF to Firebase Storage
    await uploadBytes(pdfRef, pdfBlob)
      .then(() => {
        console.log("PDF successfully uploaded");
      })
      .catch((error) => {
        console.error("Firebase Storage error:", error);
        console.error("Error uploading file:", pdfFileName);
        console.error("File blob:", pdfBlob);
        document.getElementById("spinner").classList.add("d-none");
        document.getElementById("submit-text").classList.remove("d-none");
        return;
      });

    // Get the download URL
    const downloadURL = await getDownloadURL(pdfRef);

    console.log("form data", formData);

    // Generate user document data
    const userDocData = {
      email: formData.email,
      firstname: formData.firstname,
      lastname: formData.lastname,
      stageId: 1, // 1 means user has completed the signature
    };

    // Set the document in Firestore
    await setDoc(doc(db, "users", emailHash), userDocData);
    setStage(1);

    // Function to send email
    const sendEmail = async (recipientEmail) => {
      const uniqueURL = getEmailBasedURL(formData.email);
      const emailParams = {
        to_email: recipientEmail,
        form_name: formData.emailFromName,
        form_email: formData.emailFromEmail,
        reply_to: formData.emailReplyTo,
        subject: formData.emailSubject,
        greetings: formData.emailGreetings,
        to_name: formData.firstname,
        description_one: formData.emailDescriptionOne,
        description_two: formData.emailDescriptionTwo,
        download_text: formData.downloadText,
        signature: formData.emailSignature,
        pdf_link: downloadURL,
        unique_url: `${window.location.origin}?email=${uniqueURL}`,
      };

      try {
        const result = await emailjs.send(
          "service_dd8dqr8",
          "template_jre5mwc",
          emailParams,
          "JEigABh8rJQux7G14"
        );
        console.log("Email sent successfully!", result);
      } catch (error) {
        console.error("Failed to send the email.", error);
      }
    };

    // Send the emails
    // [TODO: add API for mailchimp or Klaviyo, give the option and add parameters to allow to connect to either, ]
    await sendEmail(formData.email); // Send to client
    await sendEmail("julien@osc.consulting"); // Send to admin

    // Set formSubmitted state to true after successful form submission
    setFormSubmitted(true);
  };

  // This function handles file uploads
  const handleFileUpload = async (event) => {
    // Get the uploaded file from the event
    const file = event.target.files[0];
    if (!file) return; // If no file, return early

    // Format the current date for use in file naming
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = currentDate.getFullYear();
    const formattedDate = `${month}-${day}-${year}`;

    // Get the Firebase Storage instance and create a reference to the file location
    const storageInstance = getStorage(firebaseApp);
    const clientFolder = `${formData.email}`;
    const fileRef = ref(
      storageInstance,
      `leads/${clientFolder}/document__${formData.firstname}-${
        formData.lastname
      }-${formattedDate}.${file.name.split(".").pop()}`
    );

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file)
      .then(() => {
        console.log("File successfully uploaded"); // Log success message
        setFileUploadDone(true); // Set fileUploadDone state variable to true
      })
      .catch((error) => {
        console.error("Firebase Storage error:", error); // Log error message
      });

    // Update the document in Firestore
    await setDoc(doc(db, "users", emailHash), { stageId: 2 }, { merge: true });
    setStage(2);
  };

  const intentCheckboxRef = useRef(null);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      // Fetch the document from Firestore
      const docSnap = await getDoc(doc(db, "users", emailHash));

      if (docSnap.exists()) {
        // Update the stage in the component's state
        setStage(docSnap.data().stageId);
      }

      setLoading(false);
    };

    fetchUserData();

    if (stage === 1) {
      setFormSubmitted(true);
    }
    if (stage === 2) {
      setFileUploadDone(true);
    }
  }, [stage]);

  useEffect(() => {
    const handleResize = () => {
      setCanvasWidth(window.innerWidth > 768 ? 500 : 370);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="signature-page page">
      <header
        className="signature-header"
        style={{ backgroundColor: backgroundColor }}
      >
        <img className="signature-logo" src={logoURL} alt="business logo" />
      </header>

      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-75">
          <div className="spinner-border text-muted" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {!formSubmitted && !fileUploadDone && (
            <div className="signature-container" ref={formRef}>
              <div className="row">
                <div className="col-sm-12 col-md-5 mb-5">
                  <h2 className="contract-header">Letter To:</h2>

                  <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">name</span>
                    <input
                      type="text"
                      name="fullname"
                      className="form-control"
                      placeholder="name"
                      value={`${formData.firstname} ${formData.lastname}`}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Phone</span>
                    <Cleave
                      options={{ phone: true, phoneRegionCode: "US" }}
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group input-group-sm mb-4">
                    <span className="input-group-text">Address</span>
                    <textarea
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="name"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </div>
                  <hr className="line-break"></hr>
                </div>
                <div className="col-sm-12 col-md-7 contract-article">
                  <button
                    className="sign-button"
                    onClick={() => {
                      setHighlight(true);
                      intentCheckboxRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    Fill out
                  </button>
                  <div className="contract-text">
                    <h1 className="contract-header">
                      Attorney-Client Retainer Agreement
                    </h1>
                    <p>
                      Please read this document carefully. You must sign and
                      click the Submit button to finalize your agreement.
                    </p>
                    <div className="contract-container">
                      This agreement is made between {formData.companyname},
                      referred to in this agreement as "Attorneys" and Client,{" "}
                      {formData.firstname} {formData.lastname} , referred to in
                      this agreement as “Client,” in order to set out the terms
                      and conditions under which Attorneys will represent
                      Client.
                    </div>
                    <br />
                    <h3 className="contract-header">SECTION ONE</h3>
                    <h4 className="contract-header">SCOPE OF REPRESENTATION</h4>
                    <p>
                      Attorneys agree to represent Client in connection with
                      Client's potential antitrust and consumer claim(s)
                      relating to Client's ownership of. (hereinafter
                      “Defendant”) and Client's claim that they were overcharged
                      for games (the “Games”) purchased through. Representation
                      shall be limited to those claims related to the purchase
                      of Games from Defendant (hereinafter “Litigation”).
                    </p>
                    <p>
                      Attorneys will represent Client in an arbitration
                      proceeding and provide such reasonable legal services as
                      are necessary to prosecute the arbitration.
                    </p>
                    <p>
                      Attorneys shall represent Client only in connection with
                      this Litigation, and not any counterclaim, individual
                      claim, or appeal, whether related or not to this
                      Litigation, except as expressly stated in this Agreement.
                      Under this Agreement, Attorneys agree to represent Client,
                      personally, and not any other individual or entity related
                      to Client. Attorneys' services do not include tax advice,
                      and Client is urged to seek independent tax advice.
                    </p>
                    <p>
                      Should Attorneys file an arbitration proceeding on your
                      behalf in this Litigation, the proceeding may be a mass
                      arbitration that is also filed on behalf of other
                      similarly situated individuals or entities. Client
                      consents to Attorneys representing Client and such other
                      parties based on the principle that all potential
                      participants should benefit in the same manner from the
                      mass arbitration.
                    </p>
                    <h3 className="contract-header">SECTION</h3>
                    <h4 className="contract-header">TWO ATTORNEY FEES</h4>
                    <br />
                    Client agrees Defendant may pay Attorneys directly for their
                    fees and costs, plus interest on advanced costs. Client
                    understands and acknowledges that in this event, Attorneys'
                    fees and costs may greatly exceed Client's recovery. Client
                    also understands that Attorneys may obtain certain business
                    practice changes aimed at ending the complained of conduct
                    and that this may be part of an individual settlement.
                    Client acknowledges and agrees that Attorneys are entitled
                    to recover the value of those practice changes from which
                    Clients will benefit and that Client cannot share in that
                    recovery. Client understands that Attorneys will advance all
                    legal, investigative, and arbitration costs reasonably
                    necessary for prosecution of the Litigation. Client further
                    understands Attorneys' fees are not fixed by law and are
                    negotiable. In the event the Defendant refuses to pay
                    Attorneys directly for their fees and costs, as compensation
                    for legal services provided, Client agrees to pay attorney
                    fees as follows: Forty Percent (40%) of the amount recovered
                    from said claim whether prior to suit, after filing suit, by
                    settlement, or other legal manner resulting from Attorneys'
                    involvement or effort, in addition to any reimbursable
                    costs. The 40% will be calculated prior to the deduction of
                    costs and expenses. It is agreed between the parties that
                    any monetary recovery obtained subsequent to the execution
                    of this agreement shall be considered resulting from
                    Attorneys' involvement or effort. Client understands that
                    his or her case will be part of a mass arbitration
                    proceeding and authorizes Attorneys to enter into aggregate
                    settlement negotiations and to disclose the amount of the
                    proposed settlement values to other clients whose cases are
                    included in the aggregate of cases. Client agrees to not
                    resolve this case without first consulting with Attorneys.
                    Sanctions are punitive measures. They are payments that a
                    court orders a party to make, to compensate for
                    extraordinary time spent by the other party's attorneys
                    compelling the opposing side to do that which they are
                    required to do but have failed to do. Any sanction award
                    ordered by the court belongs to Attorneys as compensation
                    for such extraordinary time and is not considered a part of
                    the recovery made on behalf of Client. If such an award
                    includes a cost item, such as a filing fee, such costs items
                    shall be credited to Client's account upon payment thereof.
                    Client understands that a Defendant(s) may make a settlement
                    offer that is favorable to plaintiff but that is contingent
                    on Attorneys' receiving reduced fees or no fees at all.
                    However, the Parties agree that this contingency fee
                    Agreement is expressly premised on the assumption that
                    Attorneys have a right to attempt to recover attorneys' fees
                    under fee-shifting laws that provide an attorneys' fees
                    award if Client prevails. CLIENT HEREBY AGREES TO ASSIGN TO
                    ATTORNEYS ALL RIGHTS THAT CLIENT HAS TO APPLY FOR AND
                    COLLECT ANY ATTORNEYS' FEES. To the extent allowed by law,
                    Client hereby grants Attorneys a lien on any claims or
                    causes of action that are the subject of Attorneys'
                    representation under this agreement. The lien to Attorneys
                    shall be for any sums owing to Attorneys for any unpaid
                    costs or attorneys' fees at the conclusion of Attorneys'
                    services. The lien shall attach to any recovery Client may
                    obtain, whether by arbitration award, judgment, settlement
                    or otherwise. The effect of such a lien is that Attorneys
                    may be able to compel payment of attorneys' fees and/or
                    costs from any funds recovered on behalf of Client even if
                    Attorneys has been discharged before the end of the case.{" "}
                    <br />
                    <h3 className="contract-header">SECTION THREE</h3>
                    <h4 className="contract-header">ASSOCIATED COUNSEL</h4>
                    <br /> Client specifically authorizes Attorneys to associate
                    additional co-counsel if Attorneys believe it advisable or
                    necessary for the proper handling of Client's claim. Client
                    authorizes Attorneys to take all steps in this Litigation
                    deemed to be advisable for the investigation and handling of
                    Client's claim(s), which may include hiring investigators,
                    expert witnesses and/or other attorneys and filing any legal
                    action necessary. In the event that Attorneys choose to
                    associate other counsel, such association will not result in
                    any increase in the attorney's fees charged to Client.
                    Client further understands that there will be only one
                    attorney fee in the attorney fee percentage provided herein
                    and that Attorneys and any additional counsel will share
                    that Forty Percent (40%) fee pursuant to the agreement
                    between counsel. Fees will be based on the additional
                    co-counsel assuming joint responsibility and with the
                    following allocations: Mason LLP (2/3) and additional
                    co-counsel (1/3), to be identified if and when retained.
                    Additionally, Attorneys agree to pay the costs of case
                    expenses and court costs during the pendency of the case.{" "}
                    <br />
                    <h3 className="contract-header">SECTION FOUR</h3>
                    <h4 className="contract-header">COSTS AND EXPENSES</h4>
                    <br /> Client understands and agrees that costs are separate
                    and distinct from the attorney fee charged. Client
                    understands and agrees that the prosecution of any claim
                    such as Client's involves the expenditure of money for
                    litigation expenses. Client understands that litigation
                    expenses are those expenses advanced by Attorneys in pursuit
                    of the claim. Attorneys agree to advance any and all costs
                    and expenses as outlined herein, and Client is to only pay
                    these costs and expenses in the event of recovery out of
                    that recovery. In the event of no recovery in this
                    Litigation, Client will owe no attorney fee, costs, or
                    expenses. In the event of recovery, Client agrees to pay for
                    costs and expenses incurred in connection with Client's
                    case, such as costs, including but not limited to filing
                    fees, fixed by law or assessed by courts and other agencies,
                    court reporters' fees, process server's fees, long distance
                    telephone calls, messenger fees, delivery fees, postage,
                    parking, highway and bridge tolls, photocopying and other
                    reproduction costs, FAX transmission costs, clerical staff
                    overtime, word processing charges, charges for computer
                    time, and other similar items. Additionally, in the event of
                    recovery, Client agrees to pay out of that recovery
                    transportation, meals, lodging and all other costs of any
                    necessary out-of-town travel by Attorneys' personnel. Client
                    understands that certain expenses incurred will be expenses
                    which are common benefit costs, meaning they are incurred
                    through the joint effort and handling of multiple cases.
                    Client authorizes Attorneys to pro-rate expenses among all
                    of the cases which benefit from said common benefit costs.
                    Client agrees that Attorneys shall have the discretion to
                    hire, within their professional opinion, any and all experts
                    necessary for the prosecution of this action. Any and all
                    expert charges shall be reimbursed upon recovery as outlined
                    herein.
                    <br />
                    <h3 className="contract-header">SECTION FIVE</h3>
                    <h4 className="contract-header">CLIENT'S DUTIES</h4>
                    <br /> Client agrees to tell Attorneys the truth, to
                    cooperate with Attorneys, to keep Attorneys informed of any
                    developments that are relevant to the case, to faithfully
                    comply with this agreement, and to keep Attorneys advised of
                    Client's address and telephone number and any changes of
                    such address or telephone number. Client agrees to appear,
                    upon reasonable notice, for depositions and court
                    appearances. Client shall follow Attorneys' instructions to
                    retain, preserve, and provide us all materials that may
                    constitute evidence in this Litigation, including copies of
                    documents, and shall cooperate with Attorneys in obtaining
                    copies of relevant computer hard drives or other devices,
                    and information stored electronically. Client shall not
                    dispose of relevant computer equipment or electronic devices
                    involved in the case or terminate relevant accounts for
                    online or electronic services without first consulting
                    Attorneys. By signing this agreement Client certifies that:
                    • Client purchased Games on the platform in the past four
                    years. • Client has not retained another attorney to act on
                    their behalf in this matter. <br />
                    <h3 className="contract-header">SECTION SIX</h3>
                    <h4 className="contract-header">
                      TERMINATION AND WITHDRAWAL
                    </h4>
                    <br /> Attorneys reserve the right to withdraw as counsel
                    and terminate this Agreement upon reasonable notice to the
                    Client. In particular, attorneys reserve the right to
                    withdraw as counsel and terminate this Agreement entirely if
                    Client does not fulfill the terms and conditions of this
                    Agreement or if the continued prosecution of the Litigation
                    is not legally or economically feasible. Client may
                    discharge Attorneys from individual representation for any
                    reason whatsoever, provided, however, that in the event any
                    monetary recovery is obtained subsequent to Attorneys'
                    discharge, Attorneys shall be entitled to reimbursement of
                    costs advanced and incurred and payment of the reasonable
                    value of their services from any such monetary recovery. In
                    such circumstances, Attorneys shall have a lien on Client's
                    right to recovery of monetary damages arising out of the
                    Litigation, as provided by any federal or state common or
                    statutory law, for costs advanced and fees incurred in the
                    Litigation. Without any monetary recovery, Client shall not
                    be responsible for any Attorneys' fees or costs. <br />
                    <h3 className="contract-header">SECTION SEVEN</h3>
                    <h4 className="contract-header">
                      PRELIMINARY INVESTIGATION
                    </h4>
                    <br /> Client understands that Attorneys will conduct a
                    preliminary investigation of Client's claims and file suit
                    if warranted at the discretion of Attorneys. If after a
                    preliminary investigation of Client's claims, Attorneys
                    decline further representation of Client, Attorneys may
                    withdraw with no further obligation to Client. <br />
                    <h3 className="contract-header">SECTION EIGHT</h3>
                    <h4 className="contract-header">CONFLICT WAIVER</h4>
                    <br /> Client acknowledges and understands that Attorneys
                    may represent other individuals with similar claims,
                    particularly related to other individuals' purchase and use
                    of products by Defendants. Attorneys have determined that
                    they reasonably believe they will be able to provide
                    competent and diligent representation to multiple clients
                    with claims against Defendants, that the representation is
                    not prohibited by law, and that the representation does not
                    currently involve the assertion of a claim by one client
                    against another client. Client understands that although
                    Client's interests are generally consistent, it is
                    recognized and understood that differences may exist or
                    become evident during the course of Attorneys'
                    representation. Client hereby acknowledges that it is
                    beneficial to client to have Attorneys represent Client in
                    the above manner and expressly waives all conflicts
                    resulting from Attorneys' representation of multiple
                    Plaintiffs regarding litigation concerning Client's purchase
                    and use of products by Defendants.
                    <br />
                    <h3 className="contract-header">SECTION NINE</h3>
                    <h4 className="contract-header">DISCLAIMER OF GUARANTEE</h4>
                    <br /> Attorneys will use Attorneys' best efforts in
                    representing Client but makes no promises or guarantees
                    regarding the outcome of Client's case. Attorneys' comments
                    regarding the outcome of the case are mere expressions of
                    opinion. Neither do Attorneys guarantee any time frame
                    within which Client's case will be resolved. Client agrees
                    Attorneys have not represented to Client that Client will
                    recover any funds related to the litigation or will conclude
                    this Litigation within any specific time frame. <br />
                    <h3 className="contract-header">SECTION TEN</h3>
                    <h4 className="contract-header">ARBITRATION</h4>
                    <br /> Unless prohibited by law, disputes are to be
                    arbitrated. In the event of any dispute or any claim arising
                    out of the representation of Client by Attorneys, such
                    dispute shall be resolved by binding arbitration in front of
                    an arbitrator selected from the panel of arbitrators at
                    J.A.M.S. This includes any fee dispute, and any claim for
                    breach of contract, negligence, malpractice, breach of
                    fiduciary duty or other wrongdoing. If an arbitrator cannot
                    be agreed upon within 30 days of a demand for arbitration,
                    J.A.M.S. shall assign an arbitrator from its panel. CLIENT
                    ACKNOWLEDGES THAT ATTORNEYS HAVE EXPLAINED THAT BINDING
                    ARBITRATION MAY DEPRIVE CLIENT OF RIGHTS CLIENT MIGHT
                    OTHERWISE HAVE INCLUDING, WITHOUT LIMITATION, THE RIGHT TO A
                    JURY TRIAL, THE RIGHT TO APPEAL AND FULL DISCOVERY RIGHTS.
                    By signing below, I authorize Attorneys and its affiliates
                    to contact me by automated SMS text message with information
                    about the Litigation. I also authorize Attorneys to send me
                    automated marketing messages. Client can text STOP at any
                    time to unsubscribe. I understand that message/data rates
                    may apply to messages sent by the Attorney or its affiliates
                    under my cell phone plan. My text/mobile phone number is:
                    (310)884-1203 CLIENT REPRESENTS THAT CLIENT HAS CAREFULLY
                    READ AND FULLY UNDERSTANDS THIS AGREEMENT AND AGREES TO
                    FAITHFULLY COMPLY WITH ITS TERMS AND CONDITIONS.
                  </div>
                  <br />
                  <div className="form-check">
                    <input
                      className={`form-check-input ${
                        highlight ? "highlight" : ""
                      }`}
                      type="checkbox"
                      id="intent-to-sign"
                      name="intentToSign"
                      ref={intentCheckboxRef}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          intentToSign: e.target.checked,
                        })
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="intent-to-sign"
                    >
                      I intend to sign this document electronically
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className={`form-check-input ${
                        highlight ? "highlight" : ""
                      }`}
                      type="checkbox"
                      id="consent"
                      name="consent"
                      onChange={(e) =>
                        setFormData({ ...formData, consent: e.target.checked })
                      }
                    />
                    <label className="form-check-label" htmlFor="consent">
                      I consent to the use of electronic signatures and records
                    </label>
                  </div>
                  <h5 className="contract-header">Signature</h5>
                  <div className="signature-block">
                    <SignatureCanvas
                      ref={sigCanvas}
                      canvasProps={{
                        width: canvasWidth,
                        height: 200,
                        className: `sigCanvas ${highlight ? "highlight" : ""}`,
                      }}
                    />

                    <figure className="signature-line"></figure>
                  </div>
                  <div>
                    <button
                      className="btn btn-secondary me-1"
                      onClick={clearSignature}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-info me-1"
                      onClick={submitSignature}
                    >
                      <span id="submit-text">Submit</span>
                      <div
                        id="spinner"
                        className="spinner-border text-primary d-none"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {formSubmitted && !fileUploadDone && (
            <div className="mt-5 pt-5 mb-3">
              <div className="row">
                <h3 className="text-center">Client Document Upload</h3>
                <div className="d-flex justify-content-center align-items-center flex-column text-center">
                  <label htmlFor="file-upload" className="form-label fs-6">
                    Upload the file... you can also take a picture using your
                    phone.
                  </label>
                  <input
                    className="form-control upload-form mt-3"
                    type="file"
                    id="file-upload"
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
            </div>
          )}

          {fileUploadDone && (
            <div className="d-flex justify-content-center align-items-center flex-column text-center vh-75">
              <h3 className="mt-4 pt-5">Thank you!</h3>
              <p className="fs-5">
                You are all done with your application. Someone from our team
                will contact you soon!
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SignatureForm;
