import { useHistory, useLocation } from "react-router-dom";
import "../assets/main.css";

const Loading = (props) => {
  const location = useLocation();
  let history = useHistory();

  setTimeout(() => {
    const data = location.state.state.toSend;
    history.push(data.dashboardURL, {
      state: { data },
    });
  }, 3000);

  return (
    <>
      <main className="page-wrapper">
        <section className="container pt-5">
          <div className="row">
            <div className="col-md-12 pt-5 pb-5 text-center">
              <h2 className="display-4 mb-0">
                <span className="text-gradient-primary">
                  Dashboard Loading...
                </span>
              </h2>
            </div>
            <div
              className="progress-animation progress mb-3"
              style={{ maxWidth: "350px", margin: "auto", padding: "0" }}
            >
              <div
                className="progress-bar bg-gradient-primary"
                role="progressbar"
                style={{ width: "0%" }}
              ></div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Loading;
