import env from "react-dotenv";

const Pipedrive = (props) => {
  const ownerID = 15566679;

  console.log(
    "tosend",
    props.industry,
    props.employeeCount,
    props.affiliate,
    props.trackingData
  );

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  // PIPEDRIVE COMPONENT
  const pipedriveBaseURI = `https://oscconsultinginc.pipedrive.com/v1/`;
  const pipedriveToken = `?api_token=${env.REACT_APP_PIPEDRIVE_KEY}`;

  // console.log(props);
  // const addUsers = fetch(`${pipedriveBaseURI}users${pipedriveToken}`, {
  //   method: "GET",
  // })
  //   .then((res) => (res.ok ? res : Promise.reject(res)))
  //   .then((res) => {
  //     return res.json();
  //   })
  //   .catch((e) => console.log(e));

  // const getUsers = async () => {
  //   const usersData = await addUsers;
  //   console.log(usersData);
  // };
  // getUsers();

  const addOrganization = fetch(
    `${pipedriveBaseURI}organizations${pipedriveToken}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify({
        name: props.companyName,
        visible_to: 1,
        owner_id: ownerID,
      }),
    }
  )
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      return res.json();
    })
    .catch((e) => console.log(e));

  const getOrganizationData = async () => {
    const organizationData = await addOrganization;
    console.log("Pipedrive Organization", organizationData);

    const addPersons = fetch(`${pipedriveBaseURI}persons${pipedriveToken}`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        name: props.companyOwnerFullName,
        org_id: organizationData.data.id,
        phone: {
          value: props.phoneNumber.replaceAll(" ", "-"),
        },
        email: [props.emailAddress],
        owner_id: ownerID,
      }),
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => {
        return res.json();
      })
      .catch((e) => console.log(e));

    const getPersonsData = async () => {
      const personData = await addPersons;
      console.log("Pipedrive Person", personData);

      const addDeal = fetch(`${pipedriveBaseURI}deals${pipedriveToken}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          title: props.companyName,
          value: props.employeeCount * 8250 * 0.25,
          person_id: personData.data.id,
          org_id: organizationData.data.id,
          "30065a939350cd0292878729360ac8b83785b1a3": `https://osc.consulting${props.dashboardURL}`,
          "94c03cbc21d91acec6a41c9030d696fac1da78f8": props.employeeCount,
          c82609f3d3c03f375223d83fdeefa496f8a4773e: "25",
          "21f0ccfc2aa9dcc9d9ed3df28ee7b12a3b732190":
            props.employeeCount * 8250,
          "38235e8e941865e939c0af775951b4bc97be172b": props.trackingData,
          d3b2c47f213e8db3b965f7be1ce327dfdd0eca18: props.affiliate,
          "57d676f5141656b3316c69010f500ceea52f4eb8": props.industry,
        }),
      })
        .then((res) => (res.ok ? res : Promise.reject(res)))
        .then((res) => {
          return res.json();
        })
        .catch((e) => console.log(e));

      const getDealData = async () => {
        const dealData = await addDeal;
        console.log("Pipedrive Deal", dealData);
      };
      getDealData();

      // const addLead = fetch(`${pipedriveBaseURI}leads${pipedriveToken}`, {
      //   method: "POST",
      //   body: JSON.stringify({
      //     owner_id: ownerID,
      //     person_id: personData.data.id,
      //     organization_id: organizationData.data.id,
      //     title: props.companyName,
      //     value: { amount: props.employeeCount * 8250 * 0.25, currency: `USD` },
      //     "94c03cbc21d91acec6a41c9030d696fac1da78f8": props.employeeCount,
      //   }),
      // })
      //   .then((res) => (res.ok ? res : Promise.reject(res)))
      //   .then((res) => {
      //     return res.json();
      //   })
      //   .catch((e) => console.log(e));

      // const addNote = async () => {
      //   const leadData = await addLead;
      //   console.log("Pipedrive Note", leadData);

      //   if (props.message.length > 0) {
      //     fetch(`${pipedriveBaseURI}notes${pipedriveToken}`, {
      //       method: "POST",
      //       body: JSON.stringify({
      //         lead_id: leadData.data.id,
      //         content: `Impact Description from Client: ${props.message}`,
      //       }),
      //     })
      //       .then((res) => (res.ok ? res : Promise.reject(res)))
      //       .then((res) => {
      //         return res.json();
      //       })
      //       .catch((e) => console.log(e));
      //   }
      // };
      // addNote();
    };

    getPersonsData();
  };

  getOrganizationData();
};

export default Pipedrive;
