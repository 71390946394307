const Callout = (props) => {
  return (
    <section className="py-5 our-team">
      <div className="container text-center py-1 py-md-4 py-lg-5">
        <h2 className="display-5 text-white">
          Our team of experts has helped hundreds of companies receive over{" "}
          <u>$50 million</u> in cash refunds.
        </h2>
      </div>
    </section>
  );
};

export default Callout;
