import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { send } from "emailjs-com";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { getAnalytics, logEvent } from "firebase/analytics";

import "./Calendly.css";

const Calendly = (props) => {
  const location = useLocation();
  const analytics = getAnalytics();

  const [affiliateSourceOE, setAffiliateSourceOE] = useState(false);

  const [toSend, setToSend] = useState({});
  const [calendlyURI, setCalendlyURI] = useState("");

  let OE = "One Eagle Advisory";
  let CalendlyComponent;

  if (!affiliateSourceOE) {
    CalendlyComponent = (
      <InlineWidget url="https://calendly.com/info-35139/15min?hide_event_type_details=1" />
    );
  } else {
    CalendlyComponent = (
      <InlineWidget url="https://calendly.com/d/d24-t3s-rtw/free-erc-assessment-eg-oe" />
    );
  }

  useEffect(() => {
    if (location.state) {
      setToSend({
        fn: location.state.leadData.fn,
        cn: location.state.leadData.cn,
        email: location.state.leadData.email,
      });
    }

    if (localStorage.getItem("affiliate_source") === OE) {
      setAffiliateSourceOE(true);
    }

    if (calendlyURI.length > 0) {
      let tokenId = `eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjU2NDQ3OTY0LCJqdGkiOiI2ZDliYTA1Zi1lM2E2LTRjNzEtYjY2NC0xNzUyMWRiYzM3YWQiLCJ1c2VyX3V1aWQiOiJlM2NiNGUwZi0wMGVmLTRhN2UtYTVhYy0xYWQyMzgwZDRmNDUifQ.7caWUabXctB_e3M96q7H642oatH7OY-Oxk1QpNcHot79nBk0_to4sctN2ThHujKSAkDji8EeHnKG-V-8_NazLQ`;

      if (affiliateSourceOE) {
        tokenId = `eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjU3OTI3MTY3LCJqdGkiOiIwOGU5MjY1NC1mMDljLTQ2OWYtODE1NS03YjliMjkxN2Q4ZjAiLCJ1c2VyX3V1aWQiOiI4ZmY5YzczYi00MzU1LTQ2MjQtOTkxNy1hMWYxMTcxMmE4NGUifQ.WBjpJDnENZmWjbjkndtuQE_TNbA7VyDwoN8PDSbZX8YiuRF-3HgHTF9TZBk6_dqRuX40RPFUVRQlsthR8m9Ctg`;
      }

      // console.log(affiliateSourceOE, tokenId);

      fetch(calendlyURI, {
        method: "get",
        headers: new Headers({
          Authorization: `Bearer ${tokenId}`,
          Accept: "application/json",
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          const date = new Date(result.resource.start_time);
          setToSend((prevData) => {
            const userData = prevData;
            userData.date = date.toString();
          });
        });
    }
  }, [setToSend, calendlyURI, setCalendlyURI]);

  const recordEvent = (event) => {
    if (event.data.event === "calendly.event_scheduled") {
      // Log GA conversion
      logEvent(analytics, "Calendly Conversion");

      // Retreive Calendly meeting data and send it
      setCalendlyURI(event.data.payload.event.uri);
    }
  };

  useCalendlyEventListener({
    onEventScheduled: recordEvent,
  });

  return (
    <section className="container">
      <div className="row justify-content-center text-center pt-5 pb-0 mb-0 mb-lg-3">
        <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
          <h2 className="mb-4">Let's chat for 5min!</h2>
          <p className="mb-0 fs-6">
            To begin the process, schedule a call with one of our experts. Using
            Calendly below, select a date and time, fill out your info and
            schedule your call.
          </p>
        </div>
      </div>
      {CalendlyComponent}
      <div className="row justify-content-center text-center pt-5 pb-0 mb-0 mb-lg-3">
        <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11 text-danger mb-5">
          Due to high demand, if you miss your call and do not notify us at{" "}
          <a href="mailto:info@ercgeeks.com">info@ercgeeks.com</a> we will
          dismiss your case.
        </div>
      </div>
    </section>
  );
};

export default Calendly;
