import TagManager from "react-gtm-module";

const How = (props) => {
  return (
    <section className="container py-5 how-works">
      <div className="container text-center py-1 py-md-4 py-lg-5">
        <h2 className="h1 mb-4">How does it work!</h2>
        <p className="pb-3 mb-3 fw-normal">
          Say goodbye to the hassle of ERC filing. With just 5 simple steps and
          our cutting-edge ERC software, we guarantee a 100% approval rate for
          all submissions. While traditional digital filing can take up to 3
          months and paper filing a grueling 6 to 9 months, let us streamline
          the process for you and have your ERC filing completed in a
          lightning-fast 2 weeks or less!
        </p>
        <p className="h5">
          Start the process now!{" "}
          <a
            id="start-process-now"
            href="#erc-form"
            onClick={() => {
              props.handleOnClickEvents("btn - start-process-now");
              TagManager.dataLayer({
                dataLayer: {
                  event: "btn - begin your refund now",
                },
              });
            }}
            className="text-primary"
          >
            Click here!
          </a>
        </p>
      </div>
    </section>
  );
};

export default How;
