const Hero = (props) => {
  return (
    <section className="position-relative">
      <div className="position-relative bg-gradient-primary zindex-4 pt-lg-3 pt-xl-5">
        <div className="container zindex-5 pt-5 pb-3">
          <div className="row justify-content-center text-center pt-4 pb-sm-2 py-lg-5">
            <div className="col-xl-8 col-lg-9 col-md-10 pt-5 pb-0">
              <h1 className="display-5 text-light pb-0 mb-0">
                Business Owners Get An Average of{" "}
                <u className="fw-lighter">$16,478</u> per W2 Employee!
              </h1>
              <p className="fs-lg text-light mt-5">Easy and simple process</p>
              <button
                className="btn btn-success shadow-success btn-lg"
                onClick={() => {
                  props.handleOnClickEvents("btn - begin your refund now");
                }}
              >
                Begin your Refund Now!
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
