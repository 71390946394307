import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.us";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getDatabase, child, set, ref } from "firebase/database";
import Zapier from "../components/Zapier";
import Pipedrive from "../components/Pipedrive";
import TrashMail from "../components/TrashMail";
import TrashPhone from "../components/TrashPhone";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";

const PreAssessmentForm = (props) => {
  const params = new URLSearchParams(window.location.search);
  const location = useLocation();

  // log events to GA
  const analytics = getAnalytics();
  const handleOnClickEvents = (text) => {
    logEvent(analytics, text);
  };

  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init("503009271622144", options);

  // Set data to send
  const [toSend, setToSend] = useState({
    industry: "",
    employeeCount: "",
    companyOwnerFullName: "",
    companyName: "",
    companyAddress: "",
    companyCity: "",
    companyState: "",
    companyZipCode: "",
    emailAddress: "",
    phoneNumber: "",
    dashboardURL: "",
    trackingData: location.search,
    affiliate: params.get("affiliate") || "OSC",
  });

  const [enteredBusinessOwner, setEnteredBusinessOwner] = useState("");
  const [showRelationshipInput, setShowRelationshipInput] = useState(false);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);

  // Validate Zip Code
  const [showValidZipCode, setShowValidZipCode] = useState(true);
  const [enteredZipCode, setEnteredZipCode] = useState(false);
  const validateZipCode = (e) => {
    setEnteredZipCode(e.target.value);

    if (e.target.value.length >= 5) {
      if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(e.target.value)) {
        handleChange(e);
        setShowValidZipCode(true);
      } else {
        setShowValidZipCode(false);
      }
    }
  };

  const [email, setEmail] = useState("");
  const [emailValidity, setEmailValidity] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneValidity, setPhoneValidity] = useState(false);

  // Show field if not
  const [enteredOnBehalfOfOwner, setEnteredsetOnBehalfOfOwner] = useState("");
  const [showNotEligible, setShowNotEligible] = useState(false);
  const OnBehalfOfBusinessOwner = (event) => {
    setEnteredsetOnBehalfOfOwner(event.target.value);
    handleChange(event);

    if (event.target.value.toLowerCase() === "no") {
      setShowNotEligible(true);
      setShowCompanyInfo(false);
    } else {
      setShowCompanyInfo(true);
      setShowNotEligible(false);
    }
  };

  // Show who's inquiring on behalf of owner when input isn't yes
  const businessOwnerInputChange = (event) => {
    setEnteredBusinessOwner(event.target.value);
    handleChange(event);

    if (event.target.value.toLowerCase() !== "yes") {
      setShowRelationshipInput(
        <div className="col-sm-6 mb-4">
          <label
            htmlFor="inquiryOnBehalfOfBusinessOwner"
            className="form-label"
          >
            Inquiring on behalf of the business owner?
          </label>
          <select
            id="inquiryOnBehalfOfBusinessOwner"
            type="text"
            className="form-control"
            required
            onChange={OnBehalfOfBusinessOwner}
            name="inquiryOnBehalfOfBusinessOwner"
          >
            <option value="">Choose answer</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      );
      setShowCompanyInfo(false);
    } else {
      setShowRelationshipInput(<></>);
      setShowCompanyInfo(true);
    }
  };

  TagManager.dataLayer({
    dataLayer: {
      event: "pageview - pre-assessment-form",
    },
  });

  useEffect(() => {
    console.log(emailValidity);
  }, [emailValidity]);

  // store on submit
  let history = useHistory();
  const dbRef = ref(getDatabase());
  const onSubmit = (e) => {
    e.preventDefault();

    ReactPixel.track("SubmitApplication");

    console.log(toSend);
    // Create Organization, Person, and Deal in Pipedrive
    Pipedrive(toSend);

    history.push("/loading", {
      state: { toSend },
    });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });

    if (e.target.name === "emailAddress") {
      setToSend({
        ...toSend,
        dashboardURL: `/dashboard?user_email_id=${window.btoa(e.target.value)}`,
        emailAddress: e.target.value,
      });
    }
  };

  return (
    <section
      className="position-relative bg-secondary rounded-3 m-2 m-lg-5"
      id="erc-form"
    >
      <div className="container position-relative zindex-5 py-2 py-md-4 py-lg-5">
        <div className="row justify-content-center text-center pt-xl-2 mb-1 mb-lg-3">
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
            <h2 className="mb-4">Let's get started!</h2>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <form
              className="row needs-validation"
              autoComplete="off"
              onSubmit={onSubmit}
            >
              <div className="col-sm-6 mb-4 mx-auto">
                <label
                  htmlFor="businessOwner"
                  className="form-label text-center w-100"
                >
                  Are you the business owner?
                </label>
                <select
                  id="businessOwner"
                  className="form-select"
                  required
                  onChange={businessOwnerInputChange}
                  name="businessOwner"
                >
                  <option value="">Choose answer</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Bookkeeper">Bookkeeper</option>
                  <option value="CPA">CPA</option>
                </select>
                <div className="invalid-feedback">
                  What is your relationship with the business!
                </div>
              </div>

              {showRelationshipInput}

              {showNotEligible === true ? (
                <>
                  <div className="p-4 h4 text-danger">
                    At the moment we only accept applications from or on behalf
                    of business owners. Thank you for your inquiry.
                  </div>
                </>
              ) : (
                <></>
              )}

              {showCompanyInfo === true ? (
                <>
                  <h4>Company Information</h4>

                  <div className="col-sm-6 mb-4">
                    <label htmlFor="emailAddress" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      id="emailAddress"
                      className="form-control"
                      required
                      placeholder="admin@osc.consulting"
                      onBlur={(event) => setEmail(event.target.value)}
                      onChange={handleChange}
                      value={toSend.emailAddress}
                      name="emailAddress"
                    />
                    <TrashMail
                      email={email}
                      setEmailValidity={setEmailValidity}
                    />
                    <div className="invalid-feedback">
                      Please, provide a valid email address!
                    </div>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <Cleave
                      id="phoneNumber"
                      className="form-control"
                      required
                      placeholder="310-123-1234"
                      onBlur={(event) => setPhone(event.target.value)}
                      onChange={handleChange}
                      value={toSend.phoneNumber}
                      name="phoneNumber"
                      options={{ phone: true, phoneRegionCode: "US" }}
                    ></Cleave>
                    <div className="invalid-feedback">
                      Please, provide a valid phone number!
                    </div>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label htmlFor="companyName" className="form-label">
                      Company <span className="text-danger">Full Legal</span>{" "}
                      name
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      className="form-control"
                      required
                      onChange={handleChange}
                      value={toSend.companyName}
                      name="companyName"
                      placeholder="Company Name Inc."
                    />
                    <div className="invalid-feedback">
                      Please, enter your Company's Full Legal Name!
                    </div>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label htmlFor="companyAddress" className="form-label">
                      Company Address
                    </label>
                    <input
                      type="text"
                      id="companyAddress"
                      className="form-control"
                      required
                      onChange={handleChange}
                      value={toSend.companyAddress}
                      name="companyAddress"
                      placeholder="Street Address"
                    />
                    <div className="invalid-feedback">
                      Please, enter your Company's address!
                    </div>
                  </div>

                  <div className="col-sm-4 mb-4">
                    <label htmlFor="companyCity" className="form-label">
                      Company City
                    </label>
                    <input
                      type="text"
                      id="companyCity"
                      className="form-control"
                      required
                      onChange={handleChange}
                      value={toSend.companyCity}
                      name="companyCity"
                      placeholder="Camarillo"
                    />
                    <div className="invalid-feedback">
                      Please, enter your Company's address!
                    </div>
                  </div>

                  <div className="col-sm-4 mb-4">
                    <label htmlFor="companyState" className="form-label">
                      Company State
                    </label>
                    <select
                      id="companyState"
                      className="form-select"
                      onChange={handleChange}
                      value={toSend.companyState}
                      name="companyState"
                      required
                    >
                      <option value="">Select State</option>
                      <option value="Alabama">AL</option>
                      <option value="Alaska">AK</option>
                      <option value="Arizona">AZ</option>
                      <option value="Arkansas">AR</option>
                      <option value="California">CA</option>
                      <option value="Colorado">CO</option>
                      <option value="Connecticut">CT</option>
                      <option value="Delaware">DE</option>
                      <option value="Florida">FL</option>
                      <option value="Georgia">GA</option>
                      <option value="Hawaii">HI</option>
                      <option value="Idaho">ID</option>
                      <option value="Illinois">IL</option>
                      <option value="Indiana">IN</option>
                      <option value="Iowa">IA</option>
                      <option value="Kansas">KS</option>
                      <option value="Kentucky">KY</option>
                      <option value="Louisiana">LA</option>
                      <option value="Maine">ME</option>
                      <option value="Maryland">MD</option>
                      <option value="Massachusetts">MA</option>
                      <option value="Michigan">MI</option>
                      <option value="Minnesota">MN</option>
                      <option value="Mississippi">MS</option>
                      <option value="Missouri">MO</option>
                      <option value="Montana">MT</option>
                      <option value="Nebraska">NE</option>
                      <option value="Nevada">NV</option>
                      <option value="New Hampshire">NH</option>
                      <option value="New Jersey">NJ</option>
                      <option value="New Mexico">NM</option>
                      <option value="New York">NY</option>
                      <option value="North Carolina">NC</option>
                      <option value="North Dakota">ND</option>
                      <option value="Ohio">OH</option>
                      <option value="Oklahoma">OK</option>
                      <option value="Oregon">OR</option>
                      <option value="Pennsylvania">PA</option>
                      <option value="Rhode Island">RI</option>
                      <option value="South Carolina">SC</option>
                      <option value="South Dakota">SD</option>
                      <option value="Tennessee">TN</option>
                      <option value="Texas">TX</option>
                      <option value="Utah">UT</option>
                      <option value="Vermont">VT</option>
                      <option value="Virginia">VA</option>
                      <option value="Washington">WA</option>
                      <option value="West Virginia">WV</option>
                      <option value="Wisconsin">WI</option>
                      <option value="Wyoming">WY</option>
                    </select>
                    <div className="invalid-feedback">
                      Please, enter your Company's State!
                    </div>
                  </div>

                  <div className="col-sm-4 mb-4">
                    <label htmlFor="companyZipCode" className="form-label">
                      Company Zip Code{" "}
                      {showValidZipCode === true ? (
                        ""
                      ) : (
                        <span className="text-danger">Invalid</span>
                      )}
                    </label>
                    <input
                      type="number"
                      id="companyZipCode"
                      className="form-control"
                      required
                      onChange={validateZipCode}
                      onBlur={validateZipCode}
                      value={enteredZipCode}
                      name="companyZipCode"
                      placeholder="93012"
                    />
                    <div className="invalid-feedback">
                      Please, enter your Company's name!
                    </div>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label htmlFor="employeeCount" className="form-label">
                      # W2 employees in 2020 &amp; 2021?
                    </label>
                    <input
                      name="employeeCount"
                      type="number"
                      className="form-control"
                      onChange={handleChange}
                      value={toSend.employeeCount}
                      min="2"
                      step="1"
                      max="500"
                      required
                      placeholder="10"
                    />
                    <div className="invalid-feedback">
                      What is your relationship with the business!
                    </div>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="companyOwnerFullName"
                      className="form-label"
                    >
                      Owners Full Name
                    </label>
                    <input
                      type="text"
                      id="companyOwnerFullName"
                      className="form-control"
                      required
                      onChange={handleChange}
                      value={toSend.companyOwnerFullName}
                      name="companyOwnerFullName"
                      placeholder="John Smith"
                    />
                    <div className="invalid-feedback">
                      Please, enter your name!
                    </div>
                  </div>

                  <div className="col-sm-12 mb-4">
                    <label htmlFor="industry" className="form-label">
                      Industry
                    </label>
                    <select
                      id="industry"
                      className="form-select"
                      onChange={handleChange}
                      value={toSend.industry}
                      name="industry"
                      required
                    >
                      <option value="">Select Industry</option>
                      <option value="restaurant">Restaurant</option>
                      <option value="school">School</option>
                      <option value="church">Church</option>
                      <option value="retail">Retail</option>
                      <option value="service">Service</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  <div className="col-12 text-center pt-2 pt-md-3 pt-lg-4">
                    <button
                      id="submit-form"
                      type="submit"
                      className="btn btn-primary shadow-primary btn-lg"
                      disabled={!emailValidity}
                    >
                      Continue
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreAssessmentForm;
