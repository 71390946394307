import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const Reviews = (props) => {
  return (
    <section className="container py-5 my-5 px-2 px-sm-0 overflow-hidden">
      <h2 className="pt-2 pb-5 text-center">Millions Back To Businesses</h2>
      <div className="container">
        <Swiper
          modules={[Pagination, Autoplay]}
          initialSlide={1}
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={50}
          loop={true}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2500, disableOnInteraction: true }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide className="h-auto pt-4">
            <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0">
              <div className="card h-100 position-relative border-0 shadow-sm">
                <div className="card-body">
                  <span className="d-table badge shadow-primary bg-faded-primary text-primary fs-sm mb-3">
                    54 Dentists
                  </span>
                  <div className="text-end">
                    <span className="fs-xs text-uppercase">
                      AVG Per W2 Employee:
                    </span>
                    <span className="fs-2 fw-bold d-block">$16,752</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      AVG Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block">$83,761</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      Total Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block text-success">
                      $4,523,123
                    </span>
                  </div>
                </div>
              </div>
            </figure>
          </SwiperSlide>
          <SwiperSlide className="h-auto pt-4">
            <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0">
              <div className="card h-100 position-relative border-0 shadow-sm">
                <div className="card-body">
                  <span className="d-table badge bg-secondary shadow-secondary fs-sm mb-3">
                    13 Construction
                  </span>
                  <div className="text-end">
                    <span className="fs-xs text-uppercase">
                      AVG Per W2 Employee:
                    </span>
                    <span className="fs-2 fw-bold d-block">$15,822</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      AVG Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block">$1,265,766</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      Total Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block text-success">
                      $18,986,500
                    </span>
                  </div>
                </div>
              </div>
            </figure>
          </SwiperSlide>
          <SwiperSlide className="h-auto pt-4">
            <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0">
              <div className="card h-100 position-relative border-0 shadow-sm">
                <div className="card-body">
                  <span className="d-table badge bg-info shadow-info fs-sm mb-3">
                    76 Restaurants
                  </span>
                  <div className="text-end">
                    <span className="fs-xs text-uppercase">
                      AVG Per W2 Employee:
                    </span>
                    <span className="fs-2 fw-bold d-block">$14,425</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      AVG Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block">$259,661</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      Total Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block text-success">
                      $19,734,268
                    </span>
                  </div>
                </div>
              </div>
            </figure>
          </SwiperSlide>
          <SwiperSlide className="h-auto pt-4">
            <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0">
              <div className="card h-100 position-relative border-0 shadow-sm">
                <div className="card-body">
                  <span className="d-table badge shadow-success bg-faded-success text-success fs-sm mb-3">
                    8 Bakeries
                  </span>
                  <div className="text-end">
                    <span className="fs-xs text-uppercase">
                      AVG Per W2 Employee:
                    </span>
                    <span className="fs-2 fw-bold d-block">$16,418</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      AVG Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block">$114,928</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      Total Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block text-success">
                      $919,424
                    </span>
                  </div>
                </div>
              </div>
            </figure>
          </SwiperSlide>
          <SwiperSlide className="h-auto pt-4">
            <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0">
              <div className="card h-100 position-relative border-0 shadow-sm">
                <div className="card-body">
                  <span className="d-table badge shadow-warning bg-faded-warning text-warning fs-sm mb-3">
                    23 Law Firms
                  </span>
                  <div className="text-end">
                    <span className="fs-xs text-uppercase">
                      AVG Per W2 Employee:
                    </span>
                    <span className="fs-2 fw-bold d-block">$14,345</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      AVG Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block">$2,051,335</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      Total Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block text-success">
                      $47,180,705
                    </span>
                  </div>
                </div>
              </div>
            </figure>
          </SwiperSlide>
          <SwiperSlide className="h-auto pt-4">
            <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0">
              <div className="card h-100 position-relative border-0 shadow-sm">
                <div className="card-body">
                  <span className="d-table badge shadow-danger bg-faded-danger text-danger fs-sm mb-3">
                    37 Misc
                  </span>
                  <div className="text-end">
                    <span className="fs-xs text-uppercase">
                      AVG Per W2 Employee:
                    </span>
                    <span className="fs-2 fw-bold d-block">$17,069</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      AVG Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block">$631,584</span>
                    <span className="fs-xs text-uppercase mt-3 d-block">
                      Total Recovered:
                    </span>
                    <span className="fs-2 fw-bold d-block text-success">
                      $23,368,608
                    </span>
                  </div>
                </div>
              </div>
            </figure>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Reviews;
