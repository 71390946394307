const Who = (props) => {
  return (
    <section className="position-relative pt-5 pb-3">
      <div className="container position-relative zindex-5 pb-md-4 pt-md-2 py-lg-3">
        <div className="row justify-content-center text-center my-0">
          <div className="col-xl-6 col-lg-7 col-md-9">
            <h2 className="h2">Our speciality is ERC</h2>
            <p className="fs-lg text-muted mb-0">
              Join the 100% of businesses who have been approved, even if your
              earnings increased in 2020 and/or 2021!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Who;
