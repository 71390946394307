import { Accordion } from "react-bootstrap";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionItem from "react-bootstrap/esm/AccordionItem";

import parse from "html-react-parser";

const FAQs = (props) => {
  const FAQs = [
    {
      title: "What is ERC?",
      body: "The Employee Retention Credit (ERC) is a refundable payroll tax credit, up to $26,000 per employee, available through the CARES Act to businesses impacted by COVID-19.",
    },
    {
      title: "How do I qualify?",
      body: `<p>
        Many CPA's tell their client they do not qualify for
        the ERC program. We spent a lot of time understanding
        the qualifications criteria and many of these CPA's
        were wrong! There are many criteria that are specified
        in a document of more than 200 pages from the IRS that
        over the past year already got many amendments which
        make the understanding of the criteria a laborious
        process and lead some adviser to use the easy way by
        stating their client do not qualify. Some of the
        criteria that needs to be taken into consideration to
        be eligible for this program are:
      </p>
      <ul>
        <li>
          Full or partial suspension of business operations
        </li>
        <li>
          Your business experienced a reduction in revenue in
          2020 and/or 2021
        </li>
        <li>
          Your business operations were limited by commerce,
          inability to travel or restrictions of group
          meetings
        </li>
        <li>
          Your business operations suffered from your vendors
          limitations
        </li>
      </ul>`,
    },
    {
      title: "Do I have to repay the ERC Credit?",
      body: `No. ERC is a refundable tax credit not a loan. When we
    file your ERC claim a refund check will be sent to
    you.`,
    },
    {
      title: "Can't I just have my CPA file? Why would I use OSC Consulting?",
      body: `<p>
      CPA's use OSC Consulting for all their clients for a
      reason. You can surely use your CPA and try to make
      this work, however you'll quickly realize this won't
      work, and if you file with mistakes or incorrectly,
      it'll be very difficult to try again. There are
      several challenges to overcome. We've hired tax
      attorneys, CPAs, and ex IRS workers to facilitate our
      process and get a 100% approval rate.
    </p>
    <p>
      CPA's will have a lot to learn in order to file for
      the ERC on your behalf correctly and will need to
      provide a 15 page letter of opinion as well as a 50
      page ERC assessment documentation. This can take
      months to years. The ERC credit is taken on your
      payroll returns and not through your business income
      tax returns, most CPA's do not handle such services
      for your company. Because of this most CPA's don't
      process this credit, unless they process your payroll
      in house and took the time to fully understand the
      complexity of this IRS program with all the amendments
      that followed. This is also the main reason why this
      credit is so underutilized. Since CPA's don't
      typically handle it and they are the tax experts, it
      has mostly fallen in a middle ground where few are
      able to effectively process the credit.
    </p>
    <p>
      OSC Consulting sole business is to help small business
      owners in getting the maximum credit refund they are
      entitled to. Over the past year, we have been
      specifically focusing on the understanding of the
      ERC's complex qualifications criteria and maximizing
      the ERC program refund for our customers. In our
      experience and due to the complexity (the ERC tax code
      is over 200 pages) and time investment necessary to
      understand the ERC program, very few are able to
      effectively maximize this large credit for your
      business.
    </p>`,
    },
    {
      title: "Can I get ERC Funds if I already took the PPP?",
      body: `Yes. The Taxpayer Certainty and Disaster Tax Relief
    Act of 2020, enacted December 27, 2020, modified the
    ERC credit rules. One of the modifications included
    allowing a company to have a PPP loan and still take
    advantage of the ERC credit. However, you can't double
    dip.`,
    },
    {
      title:
        "My revenue went up in 2020, can I still qualify for the ERC program?",
      body: `<p>
      Yes! There are many possible ways to be eligible for
      the ERC program for 2020 and 2021 if you had an
      increase in revenue during these periods. Some of them
      include but are not limited to a full or partial
      shutdown of your business due to COVID-19, loss of
      revenue opportunities due to covid, broken supply
      chain, etc... This includes your operations being
      limited by commerce, inability to travel or
      restrictions of group meetings, staff hiring issues,
      and more.
    </p>
    <p>
      They are many examples of businesses that did not
      think they would qualify while they did due to
      COVID-19 restrictions imposed by the government:
    </p>
    <p>
      Restaurants had to fully close during a period last
      year and when they reopened they could not reopen to
      full capacity, businesses that were conducting their
      operations by meeting with clients in person and has
      to cancel meetings due to COVID-19 restrictions,
      businesses had to reduce their operating hours,
      businesses had to spend more time between clients due
      to cleaning requirements, businesses had to stop sales
      as their inventory was not delivered in time,
      businesses had to cancel events, restrict access to
      attendees, etc...
    </p>`,
    },
    {
      title:
        "I'm a CPA or a payroll company, can I refer my clients to OSC Consulting?",
      body: `Yes. We also offer a referral/affiliate programs. To learn more please contact us.`,
    },
    {
      title: "Will the ERC funds run out?",
      body: `No. This program is based on tax credit refund hence all eligible employers will receive the funds.`,
    },
    {
      title: "Can I receive more in ERC Credit than I paid in taxes?",
      body: `Indeed you may receive more than the taxes you paid.
      This program has been created to assist employers with
      the retention of employees and while it is considered
      as a credit refund, the credit is not limited to the
      taxes you paid.`,
    },
    {
      title:
        "As an owner do my wages, or the wages of any family member I employ qualify?",
      body: `A business owner and his/her spouse are able to
      include their and family members W2 wages in the ERC
      calculation if both the business owner and spouse have
      no living family members or both control less than 50%
      of the company! All other owners controlling more than
      50% of a business and any family members' W2 wages are
      not eligible for ERC.`,
    },
    {
      title:
        "Can my wages qualify for the ERC calculation if I'm self employed?",
      body: `Same as above. Now if you run your business as a sole
      proprietorship, then you do not qualify.`,
    },
    {
      title: "How much do you charge?",
      body: `<p>$0 till you get your first refund. % of the amount
      recovered depending based on total amount recovered
      & payable when you get your refund.</p>`,
    },
    {
      title: "How will I make the payment?",
      body: `It can take up to 6 months for business owners to
    receive their refund check. We offer our
    contingency program. You'll
    only pay the fees AFTER you get your refund checks.`,
    },
    {
      title: "What happens if the IRS does not send refunds?",
      body: `In such case, OSC Consulting will try to resolve this issue
    with the IRS on your behalf and you will not have to
    pay us any fees until you get a refund, if you do not
    get a refund then you will not owe any money.`,
    },
    {
      title: "How long does the ERC process take?",
      body: `It is up to you! We have automated all the process on
    this site, hence as soon as you enter all the data
    requested, we will process your application within a
    day. You will need to have sign our consulting
    agreement first as detailed above, then you will
    receive from us the form to file your claim that you
    will need to sign, then we will file them for you.
    Once filed, refunds are released based on IRS backlog.
    Currently, the IRS has stipulated a 16 week minimum
    turnaround on the ERC refunds.`,
    },
    {
      title: "Is the ERC Credit taxable?",
      body: `The ERC credit is not considered income for federal
    income tax purposes, but you must reduce any deductible
    wage expenses by the amount of the credit. Please
    provide the credit information to your CPA for tax
    purposes.`,
    },
  ];

  return (
    <section className="container py-5 ">
      <div className="container my-2 py-md-2 py-lg-5">
        <div className="row">
          <div className="col-xl-4 col-md-5 text-center text-md-start pb-2 pb-md-0 mb-4 mb-md-0">
            <h2 className="pb-3 mb-1 mb-lg-2">
              Any questions? <br className="d-none d-md-inline" />
              Check out the FAQs
            </h2>
            <p className="fs-lg pb-3 mb-2 mb-lg-3">
              Still have unanswered questions and need to get in touch?
            </p>
          </div>
          <div className="col-md-7 offset-xl-1">
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              {FAQs.map((value, index) => (
                <AccordionItem
                  className="border-0 rounded-3 shadow-sm mb-3"
                  eventKey={index}
                  key={index}
                >
                  <AccordionHeader>{value.title}</AccordionHeader>
                  <AccordionBody>{parse(`${value.body}`)}</AccordionBody>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
