import { getAnalytics, logEvent } from "firebase/analytics";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/vendor/silicon.min.css";

import Header from "../components/Header";
import Hero from "../components/Hero";
import Who from "../components/Who";
import What from "../components/What";
import Callout from "../components/Callout";
import How from "../components/How";
import Process from "../components/Process";
import Reviews from "../components/Reviews";
import FAQs from "../components/FAQs";
import Affiliate from "../components/Affiliate";
import { useHistory, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

const Homepage = (props) => {
  const params = new URLSearchParams(window.location.search);
  const location = useLocation();

  let history = useHistory();
  const analytics = getAnalytics();
  const handleOnClickEvents = (text) => {
    logEvent(analytics, text);
    history.push(`/pre-assessment-form${location.search}`);
  };

  TagManager.dataLayer({
    dataLayer: {
      event: "pageview - homepage",
    },
  });

  return (
    <>
      <Affiliate source="ERC GA" />
      <Header handleOnClickEvents={handleOnClickEvents} phone=""></Header>
      <Hero handleOnClickEvents={handleOnClickEvents} />
      <Who />
      <What />
      <Callout />
      <How handleOnClickEvents={handleOnClickEvents} />
      <Process handleOnClickEvents={handleOnClickEvents} />
      <Reviews />
      <FAQs handleOnClickEvents={handleOnClickEvents}></FAQs>
    </>
  );
};

export default Homepage;
