import { Player } from "@lottiefiles/react-lottie-player";
import lottieJson from "../assets/img/animation-financial-landing";

const What = (props) => {
  return (
    <section className="container my-5">
      <div className="card bg-white border-primary shadow-sm px-4 px-lg-0">
        <div className="row align-items-center py-3">
          <div className="col-xl-5 col-md-6 offset-lg-1 text-center text-md-start py-4 py-lg-5">
            <h2 className="h1">What is the Employee Retention Credit?</h2>
            <h3 className="h4 text-primary">
              Our Response to COVID-19 for your business!
            </h3>
            <p>
              <strong>
                TLDR; You could get a lot of cash quickly with OSC Consulting.
              </strong>
            </p>
            <p>
              The Employee Retention Credit is exactly what it sounds like. Your
              business could be entitled for a credit refund of up to $26,000
              per W2 employee that were kept on payroll during the pandemic.
            </p>
            <p>
              This is an important effort to help businesses in America remain
              stable and keep employees on payroll. This nationwide program has
              been created with the goal of creating long-needed job
              opportunities for Americans, while also helping our country
              overcome this difficult time period through growth on two fronts:
            </p>
            <ul>
              <li>Economic recovery from a pandemic</li>
              <li>Rebuild stronger than before</li>
            </ul>
          </div>
          <div className="col-xl-6 col-lg-5 col-md-6">
            <Player
              className="mx-auto"
              src={lottieJson}
              background="transparent"
              speed="1"
              loop
              autoplay
            ></Player>
          </div>
        </div>
      </div>
    </section>
  );
};

export default What;
