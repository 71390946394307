import undrawProfile from "../assets/img/undraw_profile_details_re_ch9r.svg";
import undrawCalling from "../assets/img/undraw_calling_re_mgft.svg";
import undrawSearching from "../assets/img/undraw_searching_re_3ra9.svg";
import undrawDocuments from "../assets/img/undraw_documents_re_isxv.svg";
import undrawDocs from "../assets/img/undraw_reviewed_docs_re_9lmr.svg";
import TagManager from "react-gtm-module";

const Process = (props) => {
  return (
    <section className="container bg-secondary pt-5 pb-2 pb-lg-3 position-relative">
      <div className="text-center position-absolute top-0 start-50 translate-middle">
        <a
          id="start-refund-process"
          data-scroll
          href="#erc-form"
          className="btn btn-primary shadow-primary btn-lg mb-1"
          onClick={() => {
            props.handleOnClickEvents("btn - start refund process");
            TagManager.dataLayer({
              dataLayer: {
                event: "btn - start refund process",
              },
            });
          }}
        >
          Start Refund Process
        </a>
      </div>

      <div className="row pb-5 mb-md-4 mb-lg-5 m-3">
        <div
          className="rellax col-md-6 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0"
          data-rellax-percentage="0.5"
          data-rellax-speed="0.8"
          data-disable-parallax-down="md"
        >
          <a
            href="#erc-form"
            className="float-md-end"
            onClick={() => {
              props.handleOnClickEvents("btn - undraw profile");
              TagManager.dataLayer({
                dataLayer: {
                  event: "btn - undraw profile",
                },
              });
            }}
          >
            <img src={undrawProfile} className="rounded-3" width="500" alt="" />
          </a>
        </div>
        <div
          className="rellax col-md-6 order-md-1 pt-md-4 pt-lg-5"
          data-rellax-percentage="0.5"
          data-rellax-speed="-0.6"
          data-disable-parallax-down="md"
        >
          <div className="pe-md-4 me-md-2">
            <div className="fs-sm text-muted mb-1">STEP 1</div>
            <h2 className="h3">Pre-Assessment</h2>
            <span className="d-table badge bg-faded-primary text-primary fs-sm mb-3">
              1 Hour
            </span>
            <p className="d-lg-block pb-3 mb-2 mb-md-3">
              Fill out the{" "}
              <a
                href="#erc-form"
                onClick={() => {
                  props.handleOnClickEvents("btn - step 1 link");
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "btn - step 1 link",
                    },
                  });
                }}
              >
                form here
              </a>
              . The form has a series of basic questions to determine if you
              could qualify for the ERC program. Right after submitting your
              form, you'll have the option to set up a call with us for 15-30
              minutes using calendly on which we'll talk about the business and
              its eligibility.
            </p>
            <p className="text-danger">
              Due to high demand, if you miss your scheduled call and do not
              notify us we will dismiss your case.
            </p>
          </div>
        </div>
      </div>

      <div className="row py-5 mb-md-4 mb-lg-5 m-3">
        <div
          className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0"
          data-rellax-percentage="0.5"
          data-rellax-speed="0.8"
          data-disable-parallax-down="md"
        >
          <a
            href="#erc-form"
            onClick={() => {
              props.handleOnClickEvents("btn - undraw calling");
              TagManager.dataLayer({
                dataLayer: {
                  event: "btn - undraw calling",
                },
              });
            }}
          >
            <img src={undrawCalling} className="rounded-3" width="500" alt="" />
          </a>
        </div>
        <div
          className="rellax col-md-6"
          data-rellax-percentage="0.5"
          data-rellax-speed="-0.6"
          data-disable-parallax-down="md"
        >
          <div className="ps-md-4 ms-md-2">
            <div className="fs-sm text-muted mb-1">STEP 2</div>
            <h2 className="h3">Business Assessment</h2>
            <span className="d-table badge bg-faded-primary text-primary fs-sm mb-3">
              2-3 Days
            </span>
            <p className="d-lg-block pb-3 mb-2 mb-md-3">
              During our first call, you'll describe ownership and/or
              relationship with the business as well as how the COVID-19
              pandemic has affected your business. If we determine you could
              qualify, we'll request a series of documents (941s, DE9 & DE9C,
              PPP).
            </p>
          </div>
        </div>
      </div>

      <div className="row pb-5 mb-md-4 mb-lg-5 m-3">
        <div
          className="rellax col-md-6 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0"
          data-rellax-percentage="0.5"
          data-rellax-speed="0.8"
          data-disable-parallax-down="md"
        >
          <a
            href="#erc-form"
            className="float-md-end"
            onClick={() => {
              props.handleOnClickEvents("btn - undraw searching");
              TagManager.dataLayer({
                dataLayer: {
                  event: "btn - undraw searching",
                },
              });
            }}
          >
            <img
              src={undrawSearching}
              className="rounded-3"
              width="500"
              alt=""
            />
          </a>
        </div>
        <div
          className="rellax col-md-6 order-md-1 pt-md-4 pt-lg-5"
          data-rellax-percentage="0.5"
          data-rellax-speed="-0.6"
          data-disable-parallax-down="md"
        >
          <div className="pe-md-4 me-md-2">
            <div className="fs-sm text-muted mb-1">STEP 3</div>
            <h2 className="h3">Credit Refund Assessment</h2>
            <span className="d-table badge bg-faded-primary text-primary fs-sm mb-3">
              2-3 Days
            </span>
            <p className="d-lg-block pb-3 mb-2 mb-md-3">
              Once our team has had time to analyze your documents, we'll
              provide you with a custom set of questions designed to maximize
              the refund amount you'll be eligible for depending on your
              industry and business. A second call will take place to discuss
              quarterly eligibility and final qualification criterias.
            </p>
          </div>
        </div>
      </div>

      <div className="row pb-5 mb-md-4 mb-lg-5 m-3">
        <div
          className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0"
          data-rellax-percentage="0.5"
          data-rellax-speed="0.8"
          data-disable-parallax-down="md"
        >
          <a
            href="#erc-form"
            onClick={() => {
              props.handleOnClickEvents("btn - undraw documents");
              TagManager.dataLayer({
                dataLayer: {
                  event: "btn - undraw documents",
                },
              });
            }}
          >
            <img
              src={undrawDocuments}
              className="rounded-3"
              width="500"
              alt=""
            />
          </a>
        </div>
        <div
          className="rellax col-md-6 pt-md-4 pt-lg-5"
          data-rellax-percentage="0.5"
          data-rellax-speed="-0.6"
          data-disable-parallax-down="md"
        >
          <div className="ps-md-4 ms-md-2">
            <div className="fs-sm text-muted mb-1">STEP 4</div>
            <h2 className="h3">ERC Development & Optimization</h2>
            <span className="d-table badge bg-faded-primary text-primary fs-sm mb-3">
              2-3 Days
            </span>
            <p className="d-lg-block pb-3 mb-2 mb-md-3">
              This step involves the federal worksheet, PPP forgiveness
              reallocation and optimization as well as the refund optimization.
              We'll provide you with the final total redone amount as well as
              all eligible quarters.
            </p>
          </div>
        </div>
      </div>

      <div className="row pb-5 mb-md-4 mb-lg-5 m-3">
        <div
          className="rellax col-md-6 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0"
          data-rellax-percentage="0.5"
          data-rellax-speed="0.8"
          data-disable-parallax-down="md"
        >
          <a
            href="#erc-form"
            className="float-md-end"
            onClick={() => {
              props.handleOnClickEvents("btn - undraw docs");
              TagManager.dataLayer({
                dataLayer: {
                  event: "btn - undraw docs",
                },
              });
            }}
          >
            <img src={undrawDocs} className="rounded-3" width="500" alt="" />
          </a>
        </div>
        <div
          className="rellax col-md-6 order-md-1 pt-md-4 pt-lg-5"
          data-rellax-percentage="0.5"
          data-rellax-speed="-0.6"
          data-disable-parallax-down="md"
        >
          <div className="pe-md-4 me-md-2">
            <div className="fs-sm text-muted mb-1">FINAL STEP</div>
            <h2 className="h3">ERC Assessment Report</h2>
            <span className="d-table badge bg-faded-primary text-primary fs-sm mb-3">
              5+ Days
            </span>
            <p className="d-lg-block pb-3 mb-2 mb-md-3">
              The final step is the verification process, letter of opinion
              creation, quarterly detailed assessment, and quarterly wages
              breakdown. You'll receive a 50+ page assessment as well as a
              letter of opinion ready to be sent to the IRS in order to start
              your ERC refund process.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center position-absolute top-100 start-50 translate-middle">
        <a
          data-scroll
          href="#erc-form"
          className="btn btn-primary shadow-primary btn-lg mb-1"
          onClick={() => {
            props.handleOnClickEvents("btn - begin the process");
            TagManager.dataLayer({
              dataLayer: {
                event: "btn - begin the process",
              },
            });
          }}
        >
          Begin the process
        </a>
      </div>
    </section>
  );
};

export default Process;
