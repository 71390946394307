import { useState, useEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import nineFortyOneImage from "../assets/img/941.png";
import quarterlyWagesImage from "../assets/img/quarterly-wages.png";
import pppImage from "../assets/img/3508S.png";
import profitAndLossImage from "../assets/img/P&L.png";
import { Check } from "react-bootstrap-icons";
import format from "date-fns/format";
import { PopupButton } from "react-calendly";
import { useLocation } from "react-router-dom";
import env from "react-dotenv";

import docsInReviewImage from "../assets/img/docs-in-review";
import filedDeliveredImage from "../assets/img/filed-delivered";
import filedInTransitImage from "../assets/img/filed-in-transit";
import filedProcessingImage from "../assets/img/filed-processing";
import filedScanningImage from "../assets/img/filed-scanning";
import finalizing8821Image from "../assets/img/finalizing-8821";
import finalizingInvoiceImage from "../assets/img/finalizing-invoice";
import finalizingTrackingNumberImage from "../assets/img/finalizing-tracking-number";
import inProgressCopywritingImage from "../assets/img/in-progress-copywriting";
import inProgressProcessingImage from "../assets/img/in-progress-processing";
import traceCompleteImage from "../assets/img/trace-complete";
import traceInProgressImage from "../assets/img/trace-in-progress";
import traceInTransitImage from "../assets/img/trace-in-transit";
import tracePreparingImage from "../assets/img/trace-preparing";
import amemdmentCopywritingImage from "../assets/img/amemdment-copywriting";
import amendmentProcessingImage from "../assets/img/amendment-processing";
import noticesImage from "../assets/img/notices";
import Cleave from "cleave.js/react";
import TagManager from "react-gtm-module";

const Dashboard = (props) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "pageview - dashboard",
    },
  });

  const params = new URLSearchParams(window.location.search);
  const location = useLocation();
  const encryptedEmail = params.get("user_email_id");

  let userEmail = window.atob(encryptedEmail);
  userEmail =
    userEmail.length > 0 ? userEmail : location.state.data.emailAddress;

  // PANDADOC SIGN
  const [contractSigned, setContractSigned] = useState(false);
  const [invoiceSigned, setInvoiceSigned] = useState(false);
  window.addEventListener("message", (event) => {
    const type = event.data && event.data.type;
    const payload = event.data && event.data.payload;

    if (type === "embed.form.completed") {
      if (
        payload.document.created_from_template.name === "OSC Consulting Invoice"
      ) {
        setShowForm(false, "step-invoice");
        setInvoiceSigned(true);
        updateStage(62);
      } else {
        updateLabel();
        setContractSigned(true);
        setShowForm(false, "step-6");
      }
    }
  });

  const [person, setPerson] = useState(null);
  const [activities, setActivities] = useState("");
  const [businessData, setBusinessData] = useState({
    listMajorityOwners: "",
    taxExemptEmployees: "",
    companyFoundedYear: "",
    numberIndoorTables: "",
    numberIndoorSeats: "",
    numberOutdoorTables: "",
    numberOutdoorSeats: "",
    sittingAreaSQFT: "",
    kitchenSQFT: "",
    timeToServePartiesUnderFive2019: "",
    timeToServePartiesAtLeastFive2019: "",
    timeToServePartiesUnderFive2020: "",
    timeToServePartiesAtLeastFive2020: "",
  });

  const [deal, setDeal] = useState([]);
  const [tempDeal, setTempDeal] = useState([]);
  const [declinedDocumentList, setDeclinedDocumentList] = useState([]);

  const [basicInfoCompleted, setBasicInfoCompleted] = useState(false);
  const isBasicInfoCompleted = (deal) => {
    if (deal.de06ce132480daa0c89600a446d4ca19aaa23dcd === null) return;
    if (deal.de06ce132480daa0c89600a446d4ca19aaa23dcd.length > 0)
      businessData.listMajorityOwners =
        deal.de06ce132480daa0c89600a446d4ca19aaa23dcd;
    if (deal.d628cdc49eaa25cef67830134f8b186801f5736f.length > 0)
      businessData.taxExemptEmployees =
        deal.d628cdc49eaa25cef67830134f8b186801f5736f;
    if (deal["109d2b7f01addf730bb9155a4436a76959a1ba6a"].length > 0)
      businessData.companyFoundedYear =
        deal["109d2b7f01addf730bb9155a4436a76959a1ba6a"];
    if (deal["75779ebc9023a633bf9dcc0304b2d0c615fc9967"].length > 0)
      businessData.numberIndoorTables =
        deal["75779ebc9023a633bf9dcc0304b2d0c615fc9967"];
    if (deal["54f106286408d55c4d1c54b1fe94d6c83768387b"].length > 0)
      businessData.numberIndoorSeats =
        deal["54f106286408d55c4d1c54b1fe94d6c83768387b"];
    if (deal["1aae0d07377cd7a32141cc816325170018a21849"].length > 0)
      businessData.numberOutdoorTables =
        deal["1aae0d07377cd7a32141cc816325170018a21849"];
    if (deal.d3aaaf197bd9488f6c3b8b285fd44077f34eb3ae.length > 0)
      businessData.numberOutdoorSeats =
        deal.d3aaaf197bd9488f6c3b8b285fd44077f34eb3ae;
    if (deal["0eaeb6fe0438befd8fc6aee55eace0ad916a25cc"].length > 0)
      businessData.sittingAreaSQFT =
        deal["0eaeb6fe0438befd8fc6aee55eace0ad916a25cc"];
    if (deal.e9a9ed36a18bd1ae1a044f6012a7a5acc44972b6.length > 0)
      businessData.kitchenSQFT = deal.e9a9ed36a18bd1ae1a044f6012a7a5acc44972b6;
    if (deal.cbbf945c2005f1169274efcadcd3430ea9ab93b4.length > 0)
      businessData.timeToServePartiesUnderFive2019 =
        deal.cbbf945c2005f1169274efcadcd3430ea9ab93b4;
    if (deal.bdda9c16bca59b0f3cae584db9eb9883c2b952c6.length > 0)
      businessData.timeToServePartiesAtLeastFive2019 =
        deal.bdda9c16bca59b0f3cae584db9eb9883c2b952c6;
    if (deal["5620003b9110d793b7a6a7d4f53c9542535c17d1"].length > 0)
      businessData.timeToServePartiesUnderFive2020 =
        deal["5620003b9110d793b7a6a7d4f53c9542535c17d1"];
    if (deal["0105b8530c55c0565bc9bd4aec9a3396d8ec8f14"].length > 0)
      businessData.timeToServePartiesAtLeastFive2020 =
        deal["0105b8530c55c0565bc9bd4aec9a3396d8ec8f14"];

    if (
      deal.de06ce132480daa0c89600a446d4ca19aaa23dcd.length > 0 &&
      deal.d628cdc49eaa25cef67830134f8b186801f5736f.length > 0 &&
      deal["109d2b7f01addf730bb9155a4436a76959a1ba6a"].length > 0 &&
      deal["75779ebc9023a633bf9dcc0304b2d0c615fc9967"].length > 0 &&
      deal["54f106286408d55c4d1c54b1fe94d6c83768387b"].length > 0 &&
      deal["1aae0d07377cd7a32141cc816325170018a21849"].length > 0 &&
      deal.d3aaaf197bd9488f6c3b8b285fd44077f34eb3ae.length > 0 &&
      deal["0eaeb6fe0438befd8fc6aee55eace0ad916a25cc"].length > 0 &&
      deal.e9a9ed36a18bd1ae1a044f6012a7a5acc44972b6.length > 0 &&
      deal.cbbf945c2005f1169274efcadcd3430ea9ab93b4.length > 0 &&
      deal.bdda9c16bca59b0f3cae584db9eb9883c2b952c6.length > 0 &&
      deal["5620003b9110d793b7a6a7d4f53c9542535c17d1"].length > 0 &&
      deal["0105b8530c55c0565bc9bd4aec9a3396d8ec8f14"].length > 0
    ) {
      setBasicInfoCompleted(true);
    } else {
      setBasicInfoCompleted(false);
    }
  };

  // GET/SET TRACKING NUMBER
  const [trackingNumber, setTrackingNumber] = useState({
    trackingNumber: "",
  });
  const [trackingNumberCompleted, setTrackingNumberCompleted] = useState(false);
  const isTrackingNumberCompleted = (deal) => {
    if (
      deal["d98d7e807aa02cf52be183fd074ca9b9132729c4"] &&
      deal["d98d7e807aa02cf52be183fd074ca9b9132729c4"].length > 0
    ) {
      trackingNumber.trackingNumber =
        deal["d98d7e807aa02cf52be183fd074ca9b9132729c4"];
      setTrackingNumberCompleted(true);
    } else {
      setTrackingNumberCompleted(false);
    }
  };

  const [validUser, setValidUser] = useState();
  // GET PERSON FROM PIPEDRIVE
  useEffect(() => {
    (async () => {
      const response = await props.getPerson({ userEmail });
      console.log(response, userEmail);
      if (response.data.items.length > 0) {
        setValidUser(true);
        setPerson(response.data.items[0].item);
      } else {
        setValidUser(false);
      }
    })();
  }, [userEmail, props]);

  // GET DEAL FROM PIPEDRIVE
  const [stage, setStage] = useState(6); //Docs Default
  const [pipeline, setPipeline] = useState(6); //Docs Default
  useEffect(() => {
    if (!person) return;
    const personId = person.id;
    (async () => {
      const response = await props.getDeal({ personId });
      console.log(person, response);
      const dealData = response.data[0];
      console.log(dealData);
      setDeal(dealData);
      setTempDeal(dealData);
      isBasicInfoCompleted(dealData);
      isTrackingNumberCompleted(dealData);
      setStage(dealData.stage_id);
      setPipeline(dealData.pipeline_id);

      console.log("deal", dealData);

      if (dealData.label === 30) {
        setContractSigned(true);
      } else {
        setContractSigned(false);
      }

      // set contract url if exists
      if (
        dealData.b0048a8d9feb63dc10f82d623bf05aeb49632cdd &&
        dealData.b0048a8d9feb63dc10f82d623bf05aeb49632cdd.length !== 0
      )
        setContractURL(dealData.b0048a8d9feb63dc10f82d623bf05aeb49632cdd);

      // Check if Document Declined Reasons exists
      if (dealData["98c4b8192243782bd2ea88da5eaf1935629bb65d"] !== null) {
        setDeclinedDocumentList(
          dealData["98c4b8192243782bd2ea88da5eaf1935629bb65d"]
            .split("-")
            .splice(1)
        );
      }
    })();
  }, [person]);

  // const pipedriveBaseURI = `https://oscconsultinginc.pipedrive.com/v1/`;
  // const pipedriveToken = `api_token=${env.REACT_APP_PIPEDRIVE_KEY}`;
  // GET PIPELINES & STAGES
  // useEffect(() => {
  //   fetch(`${pipedriveBaseURI}pipelines?${pipedriveToken}`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("pipeline", data.data);
  //     });
  //   fetch(`${pipedriveBaseURI}stages?${pipedriveToken}`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("stages", data.data);
  //     });
  // }, []);

  const areAllFilesPresent = (files, requiredFiles) => {
    return requiredFiles.every((file) => files.includes(file));
  };
  const countPresentFiles = (files, requiredFiles) => {
    return requiredFiles.filter((file) => files.includes(file)).length;
  };

  // INIT 941s
  const [nineFortyOnesUploadCompleted, setNineFortyOnesUploadComplete] =
    useState(false);
  const [number941sRequired, setNumber941sRequired] = useState(0);
  const nineFortyOneRequiredFiles = [
    "2020-Q1__941",
    "2020-Q2__941",
    "2020-Q3__941",
    "2020-Q4__941",
    "2021-Q1__941",
    "2021-Q2__941",
    "2021-Q3__941",
  ];
  // INIT WAGES
  const [wagesUploadCompleted, setWagesUploadCompleted] = useState(0);
  const [numberWagesRequired, setNumberWagesRequired] = useState(0);
  const wagesRequiredFiles = [
    "2020-Q1__WAGES",
    "2020-Q2__WAGES",
    "2020-Q3__WAGES",
    "2020-Q4__WAGES",
    "2021-Q1__WAGES",
    "2021-Q2__WAGES",
    "2021-Q3__WAGES",
  ];
  // INIT P&L
  const [profitLossUploadCompleted, setProfitLossUploadCompleted] = useState(0);
  const [numberProfitLossRequired, setNumberProfitLossRequired] = useState(0);
  const profitLossRequiredFiles = [
    "2019-Q1__PL",
    "2019-Q2__PL",
    "2019-Q3__PL",
    "2019-Q4__PL",
    "2020-Q1__PL",
    "2020-Q2__PL",
    "2020-Q3__PL",
    "2020-Q4__PL",
    "2021-Q1__PL",
    "2021-Q2__PL",
    "2021-Q3__PL",
  ];
  // INIT PPP
  const [PPPUploadCompleted, setPPPUploadCompleted] = useState(0);
  const [numberPPPRequired, setNumberPPPRequired] = useState(0);
  const PPPRequiredFiles = ["2020__PPP", "2021__PPP"];

  // UPDATE FILE COUNT UPLOADED
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePills = (state, fileNames, RequiredFileNames) => {
    state(countPresentFiles(fileNames, RequiredFileNames) || 0);
  };

  // GET FILES FROM PIPEDRIVE
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (!person) return;
    (async () => {
      const organizationId = person.organization.id;
      const response = await props.listFiles({ organizationId });
      const filesObject = response.data;
      let fileNames = [];

      if (!filesObject) return;
      for (let i = 0; i < filesObject.length; i++) {
        if (filesObject[i].description === null)
          filesObject[i].description = "processing";
        if (!filesObject[i].description.includes("declined"))
          fileNames.push(filesObject[i].name);
      }

      // Check 941s
      if (!areAllFilesPresent(fileNames, nineFortyOneRequiredFiles)) {
        setNineFortyOnesUploadComplete(false);
        setFiles(filesObject);
      } else {
        setNineFortyOnesUploadComplete(true);
      }
      updatePills(setNumber941sRequired, fileNames, nineFortyOneRequiredFiles);

      // Check WAGES
      if (!areAllFilesPresent(fileNames, wagesRequiredFiles)) {
        setWagesUploadCompleted(false);
        setFiles(filesObject);
      } else {
        setWagesUploadCompleted(true);
      }
      updatePills(setNumberWagesRequired, fileNames, wagesRequiredFiles);

      // Check P&Ls
      if (!areAllFilesPresent(fileNames, profitLossRequiredFiles)) {
        setProfitLossUploadCompleted(false);
        setFiles(filesObject);
      } else {
        setProfitLossUploadCompleted(true);
      }
      updatePills(
        setNumberProfitLossRequired,
        fileNames,
        profitLossRequiredFiles
      );

      // Check PPP
      if (!areAllFilesPresent(fileNames, PPPRequiredFiles)) {
        setPPPUploadCompleted(false);
        setFiles(filesObject);
      } else {
        setPPPUploadCompleted(true);
      }
      updatePills(setNumberPPPRequired, fileNames, PPPRequiredFiles);
    })();
  }, [person]);

  // DOWNLOAD 8821 FILE
  const [isFileDownloaded, setIsFileDownloaded] = useState(false);
  const download8821 = async () => {};

  // UPDATE CHOOSE FILE BUTTON STATUS
  const showFileStatus = () => {
    // console.log("show files", files);
    if (!files) return;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // console.log(file);
      setTimeout(() => {
        if (!document.querySelector(`#Y${file.name}`)) return;
        if (
          file.description &&
          file.description.toLowerCase().includes("declined")
        ) {
          document.querySelector(`#Y${file.name}`).classList.add("declined");
        } else if (
          file.description &&
          file.description.toLowerCase().includes("approved")
        ) {
          document.querySelector(`#Y${file.name}`).classList.add("approved");
        } else {
          document.querySelector(`#Y${file.name}`).classList.add("processing");
        }
      }, 0);
    }
  };

  const updateTempFiles = (filename) => {
    const tempFile = { name: filename };
    setFiles([...files, tempFile]);
  };

  // GET ACTIVITIES FROM PIPEDRIVE
  useEffect(() => {
    if (!deal.id) return;
    const dealId = deal.id;
    (async () => {
      const response = await props.getActivities({ dealId });
      setActivities(response.data);
    })();
  }, [deal]);

  // UPDATE AGREEMENT SIGNED
  const updateLabel = () => {
    if (!deal.id) return;
    const dealId = deal.id;
    const fieldName = "label";
    const fieldValue = 30;

    (async () => {
      const response = await props.updateDeal({
        dealId,
        fieldName,
        fieldValue,
      });
      console.log(response);
    })();
  };

  const [showForm, setShowForm] = useState({});

  // SCROLL TO FORM
  const stepRef = useRef(null);
  const formRef = useRef(null);
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showForm]);

  // SUBMIT FORMS
  const onSubmit = (e, step) => {
    // console.log(e, step);
    e.preventDefault();
    setShowForm(false, step);

    if (step === "step-1") setBasicInfoCompleted(true);
  };

  // SET BASIC INFO ON CHANGE
  const handleChange = (e) => {
    setBusinessData({
      ...businessData,
      [e.target.name]: e.target.value,
    });
  };

  // SET TRACKING INFO
  const handleChangeTracking = (e) => {
    setTrackingNumber({
      ...trackingNumber,
      [e.target.name]: e.target.value,
    });
  };

  // PUT BASIC INFO IN PIPEDRIVE
  const pushBasicInformation = (e) => {
    const dealId = deal.id;
    (async () => {
      const listMajorityOwners = businessData.listMajorityOwners;
      const taxExemptEmployees = businessData.taxExemptEmployees;
      const companyFoundedYear = businessData.companyFoundedYear;
      const numberIndoorTables = businessData.numberIndoorTables;
      const numberIndoorSeats = businessData.numberIndoorSeats;
      const numberOutdoorTables = businessData.numberOutdoorTables;
      const numberOutdoorSeats = businessData.numberOutdoorSeats;
      const sittingAreaSQFT = businessData.sittingAreaSQFT;
      const kitchenSQFT = businessData.kitchenSQFT;
      const timeToServePartiesUnderFive2019 =
        businessData.timeToServePartiesUnderFive2019;
      const timeToServePartiesAtLeastFive2019 =
        businessData.timeToServePartiesAtLeastFive2019;
      const timeToServePartiesUnderFive2020 =
        businessData.timeToServePartiesUnderFive2020;
      const timeToServePartiesAtLeastFive2020 =
        businessData.timeToServePartiesAtLeastFive2020;
      const response = await props.putBasicInformation({
        dealId,
        listMajorityOwners,
        taxExemptEmployees,
        companyFoundedYear,
        numberIndoorTables,
        numberIndoorSeats,
        numberOutdoorTables,
        numberOutdoorSeats,
        sittingAreaSQFT,
        kitchenSQFT,
        timeToServePartiesUnderFive2019,
        timeToServePartiesAtLeastFive2019,
        timeToServePartiesUnderFive2020,
        timeToServePartiesAtLeastFive2020,
      });
      console.log(response);
    })();
  };

  // TRACKING NUMBER
  const [trackingButton, setTrackingButton] = useState(false);
  const pushTrackingNumber = (e) => {
    const dealId = deal.id;
    const tracking = trackingNumber.trackingNumber;
    if (tracking.length !== 19) return;
    setTrackingButton(true);
    (async () => {
      const response = await props.putTrackingNumber({
        dealId,
        tracking,
      });
      console.log("tracking response", response.data);
      if (response.data) {
        updatePipeline(3);
        updateStage(14);
      }
    })();
  };

  // UPDATE PIPELINE
  const updatePipeline = (id) => {
    if (!deal.id) return;
    const dealId = deal.id;
    const fieldName = "pipeline_id";
    const fieldValue = id;

    (async () => {
      const response = await props.updateDeal({
        dealId,
        fieldName,
        fieldValue,
      });
      if (response.data) {
        setPipeline(id);
      }
    })();
  };

  // UPDATE STAGE
  const updateStage = (stageID) => {
    if (!deal.id) return;
    const dealId = deal.id;
    const fieldName = "stage_id";
    const fieldValue = stageID;

    (async () => {
      const response = await props.updateDeal({
        dealId,
        fieldName,
        fieldValue,
      });
      if (response.data) {
        setStage(stageID);
      }
    })();
  };

  // PIPEDRIVE FILE STORAGE
  const [uploading, setUploading] = useState({});
  const uploadedComplete = (id) => {
    document.querySelector(`#Y${id}`).classList.add("uploaded");
  };

  // COMING SOON
  // const uploadFile = (e, name) => {
  //   e.preventDefault();
  //   setUploading({
  //     ...uploading,
  //     [name]: true,
  //   });

  //   if (!e.target.files) return;
  //   let formdata = new FormData();
  //   formdata.append("file", e.target.files[0], name);
  //   formdata.append("deal_id", deal.id);

  //   console.log(formdata.get("file"));

  //   (async () => {
  //     const anObject = formdata.get("file");
  //     console.log("File data ", anObject);

  //     const response = await props.putFiles({ formData: anObject });
  //     document.querySelector(`#Y${name}`).classList.add("uploaded");
  //     uploadedComplete(name);
  //     setUploading({
  //       ...uploading,
  //       [name]: false,
  //     });
  //     console.log("Callable response", response);

  //   //   fetch(
  //   //     `https://us-central1-osc-consulting.cloudfunctions.net/uploadFiles`,
  //   //     { method: "post", body: formdata }
  //   //   )
  //   //     .then((res) => (res.ok ? res : Promise.reject(res)))
  //   //     .then((data) => console.log(data));
  //   })();
  // };

  // UPLOAD FILES
  const uploadFiles = (e, name) => {
    e.preventDefault();
    setUploading({
      ...uploading,
      [name]: true,
    });

    if (
      name.includes("941") &&
      number941sRequired < nineFortyOneRequiredFiles.length
    )
      setNumber941sRequired(number941sRequired + 1);
    if (
      name.includes("WAGES") &&
      numberWagesRequired < wagesRequiredFiles.length
    )
      setNumberWagesRequired(numberWagesRequired + 1);
    if (
      name.includes("PL") &&
      numberProfitLossRequired < profitLossRequiredFiles.length
    )
      setNumberProfitLossRequired(numberProfitLossRequired + 1);
    if (name.includes("PPP") && numberPPPRequired < PPPRequiredFiles.length)
      setNumberPPPRequired(numberPPPRequired + 1);

    if (name.includes("8821__signed")) updateStage(59);

    if (!e.target.files) return;
    const formData = new FormData();
    formData.append("file", e.target.files[0], name);
    formData.append("deal_id", deal.id);

    fetch(
      `https://oscconsultinginc.pipedrive.com/v1/files?api_token=${env.REACT_APP_PIPEDRIVE_KEY}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((data) => {
        if (data.status === 201) {
          document.querySelector(`#Y${name}`).classList.add("uploaded");
          updateTempFiles(name);
          uploadedComplete(name);
          setUploading({
            ...uploading,
            [name]: false,
          });

          if (number941sRequired + 1 === nineFortyOneRequiredFiles.length)
            setNineFortyOnesUploadComplete(true);
          if (numberWagesRequired + 1 === wagesRequiredFiles.length)
            setWagesUploadCompleted(true);
          if (numberProfitLossRequired + 1 === profitLossRequiredFiles.length)
            setProfitLossUploadCompleted(true);
          if (numberPPPRequired + 1 === PPPRequiredFiles.length)
            setPPPUploadCompleted(true);

          return data.json();
        }
      })
      .catch((e) => console.log(e));
  };

  // CHECK/UPDATE CONTRACT URL
  const updateContractURL = async () => {
    const personId = person.id;
    const response = await props.getDeal({ personId });

    if (
      response.data[0].b0048a8d9feb63dc10f82d623bf05aeb49632cdd === null ||
      response.data[0].b0048a8d9feb63dc10f82d623bf05aeb49632cdd.length === 0
    ) {
      setTimeout(() => {
        console.log("call deal api");
        updateContractURL();
      }, 1000);
    } else {
      console.log("set contract");
      setContractURL(response.data[0].b0048a8d9feb63dc10f82d623bf05aeb49632cdd);
    }
  };

  const updateInvoiceURL = async () => {
    const personId = person.id;
    const response = await props.getDeal({ personId });

    if (
      response.data[0]["0d6fd294eeb8c394f8cb28a8d553180f66329cbf"].length === 0
    ) {
      setTimeout(() => {
        console.log("call deal api");
        updateInvoiceURL();
      }, 1000);
    } else {
      setInvoiceURL(
        response.data[0]["0d6fd294eeb8c394f8cb28a8d553180f66329cbf"]
      );
    }
  };

  // CREATE AGREEMENT
  const [contractURL, setContractURL] = useState();
  const createAgreement = () => {
    if (contractURL) return;
    console.log("create contract");
    const zapierHook = `https://hooks.zapier.com/hooks/catch/14223798/3bu6pyg/`;

    fetch(`${zapierHook}`, {
      method: "POST",
      body: JSON.stringify({
        dealId: deal.id,
        companyName: deal.org_name,
        emailAddress: deal.person_id.email[0].value,
        phoneNumber: deal.person_id.phone[0].value,
        fee: deal.c82609f3d3c03f375223d83fdeefa496f8a4773e,
      }),
    })
      .then((res) => {
        // console.log(res.clone().json());
        return res.json();
      })
      .then((res) => {
        // console.log(res);
        updateContractURL();
      })
      .catch((e) => console.log(e));
  };

  // CREATE INVOICE
  const [invoiceURL, setInvoiceURL] = useState();
  const createInvoice = () => {
    if (invoiceURL) return;
    console.log("create invoice");
    const zapierHook = `https://hooks.zapier.com/hooks/catch/14223798/3b4rmud/`;

    fetch(`${zapierHook}`, {
      method: "POST",
      body: JSON.stringify({
        dealId: deal.id,
      }),
    })
      .then((res) => {
        console.log(res.clone().json());
        return res.json();
      })
      .then((res) => {
        console.log(res);
        updateInvoiceURL();
      })
      .catch((e) => console.log(e));
  };

  return (
    <main className="page-wrapper">
      {/* LOADER */}

      <section className="loader container pt-5">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5 text-center">
            <h3 className="mb-0">
              <span className="text-gradient-primary">
                Dashboard Loading...
              </span>
            </h3>
          </div>
          <div
            className="progress-animation progress mb-3"
            style={{ maxWidth: "350px", margin: "auto", padding: "0" }}
          >
            <div
              className="progress-bar fast bg-gradient-primary"
              role="progressbar"
              style={{ width: "0%" }}
            ></div>
          </div>
        </div>
      </section>

      <header className="header navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between">
        <section className="container">
          <div className="row">
            <div className="logo">
              OSC <span className="logo-sub">Consulting</span>
            </div>
            <div className="steps steps-sm">
              <div className="btn-help step-number bg-faded-danger">
                <PopupButton
                  className="btn callus step-number-inner bg-danger"
                  url="https://calendly.com/info-35139/30min"
                  rootElement={document.getElementById("root")}
                  text="Help!"
                />
              </div>
            </div>
          </div>
        </section>
      </header>

      <section className="container">
        {validUser ? (
          <div className="row">
            {/* ASIDE LIST */}
            {pipeline !== 8 && (
              <aside className="aside col-lg-3 col-md-4 border-end pb-lg-5 mt-n5">
                <div className="position-sticky top-0">
                  <div className="text-center pt-5 mt-5">
                    <ul
                      id="account-menu"
                      className="list-group list-group-flush collapse d-md-block"
                    >
                      <li
                        className={`list-group-item list-group-item-action d-flex align-items-center ${
                          pipeline === 6 && "active"
                        }`}
                      >
                        1. Provide Information
                      </li>
                      {pipeline === 6 && (
                        <ul className="list-group list-group-flush text-start ms-4">
                          <li
                            className={`list-group-item ${
                              stage === 34 && "current"
                            }`}
                          >
                            Requested
                          </li>
                          {stage === 37 && (
                            <li className="list-group-item current">
                              Declined
                            </li>
                          )}
                          <li
                            className={`list-group-item ${
                              (stage === 35 || stage === 36) && "current"
                            }`}
                          >
                            In Review
                          </li>
                        </ul>
                      )}

                      <li
                        className={`list-group-item list-group-item-action d-flex align-items-center ${
                          pipeline === 2 && "active"
                        }`}
                      >
                        2. In Progress
                      </li>
                      {pipeline === 2 && (
                        <ul className="list-group list-group-flush text-start ms-4">
                          <li
                            className={`list-group-item ${
                              stage === 9 && "current"
                            }`}
                          >
                            Processing
                          </li>
                          <li
                            className={`list-group-item ${
                              stage === 10 && "current"
                            }`}
                          >
                            Copywriting
                          </li>
                        </ul>
                      )}

                      <li
                        className={`list-group-item list-group-item-action d-flex align-items-center ${
                          pipeline === 10 && "active"
                        }`}
                      >
                        3. Finalizing
                      </li>
                      {pipeline === 10 && (
                        <ul className="list-group list-group-flush text-start ms-4">
                          <li
                            className={`list-group-item ${
                              stage === 61 && "current"
                            }`}
                          >
                            Invoice
                          </li>
                          <li
                            className={`list-group-item ${
                              stage === 62 && "current"
                            }`}
                          >
                            8821
                          </li>
                          <li
                            className={`list-group-item ${
                              stage === 59 && "current"
                            }`}
                          >
                            Download Files
                          </li>
                          {stage === 60 && (
                            <li
                              className={`list-group-item ${
                                stage === 60 && "current"
                              }`}
                            >
                              Missing Tracking Number
                            </li>
                          )}
                        </ul>
                      )}

                      <li
                        className={`list-group-item list-group-item-action d-flex align-items-center ${
                          (pipeline === 3 ||
                            pipeline === 4 ||
                            pipeline === 9) &&
                          "active"
                        }`}
                      >
                        4. Filed
                      </li>
                      {pipeline === 3 && (
                        <ul className="list-group list-group-flush text-start ms-4">
                          <li
                            className={`list-group-item ${
                              stage === 14 && "current"
                            }`}
                          >
                            In Transit
                          </li>
                          <li
                            className={`list-group-item ${
                              stage === 55 && "current"
                            }`}
                          >
                            Delivered
                          </li>
                          <li
                            className={`list-group-item ${
                              stage === 15 && "current"
                            }`}
                          >
                            Scanned
                          </li>
                          <li
                            className={`list-group-item ${
                              stage === 16 && "current"
                            }`}
                          >
                            Processing
                          </li>
                        </ul>
                      )}
                      {pipeline === 4 && (
                        <>
                          <ul className="list-group list-group-flush text-start ms-4">
                            <li className="list-group-item">
                              <strong>Trace Refund</strong>
                            </li>
                            <li
                              className={`list-group-item ms-3 ${
                                stage === 52 && "current"
                              }`}
                            >
                              Preparing
                            </li>
                            <li
                              className={`list-group-item ms-3 ${
                                stage === 51 && "current"
                              }`}
                            >
                              In Transit
                            </li>
                            <li
                              className={`list-group-item ms-3 ${
                                stage === 23 && "current"
                              }`}
                            >
                              In Progress
                            </li>
                            <li
                              className={`list-group-item ms-3 ${
                                stage === 24 && "current"
                              }`}
                            >
                              Complete
                            </li>
                          </ul>
                        </>
                      )}
                      {pipeline === 9 && (
                        <>
                          <ul className="list-group list-group-flush text-start ms-4">
                            <li className="list-group-item">
                              <strong>Amendement</strong>
                            </li>
                            <li
                              className={`list-group-item ms-3 ${
                                stage === 47 && "current"
                              }`}
                            >
                              Processing
                            </li>
                            <li
                              className={`list-group-item ms-3 ${
                                stage === 48 && "current"
                              }`}
                            >
                              Copywriting
                            </li>
                          </ul>
                        </>
                      )}

                      <li
                        className={`list-group-item list-group-item-action d-flex align-items-center ${
                          pipeline === 7 && "active"
                        }`}
                      >
                        5. Notices
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            )}

            <div className="main-content col-md-8">
              {/* INFORMATION STAGE */}
              {pipeline === 6 && (
                <>
                  {stage !== 37 && stage !== 35 && stage !== 36 && (
                    <>
                      <h1 className="h2">Provide Information</h1>
                      <p className="fs-6 mb-4 stage-description">
                        Welcome to your dashboard, begin your application by
                        following the steps below. Once you're all done, you can
                        submit your application for review!
                      </p>
                    </>
                  )}

                  {stage === 37 && (
                    <>
                      <h1 className="h2">Information Stage</h1>
                      <h4 className="h4 text-danger">Fix Documents</h4>
                      <p className="fs-6 text-danger stage-description">
                        It looks like one or multiple documents that you
                        provided have been declined, please re-upload the
                        file(s) listed below:
                      </p>

                      <ul className="text-danger">
                        {declinedDocumentList.map((doc, i) => (
                          <li key={i}>{doc}</li>
                        ))}
                      </ul>

                      <p className="fs-6 text-danger stage-description">
                        <strong>
                          Once you're done uploading, re-submit your application
                          on step 7!
                        </strong>
                      </p>
                    </>
                  )}

                  {stage !== 35 && stage !== 36 && (
                    <div className="steps steps-sm">
                      {/* STEP BASIC INFO */}
                      <div className="step mt-5 pb-5" ref={stepRef}>
                        {basicInfoCompleted ? (
                          <div className="step-number bg-faded-success">
                            <div className="step-number-inner bg-success text-white h1">
                              <Check />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`step-number ${
                              stage === 37 &&
                              deal[
                                "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                              ].includes("basic") &&
                              "bg-faded-danger"
                            }`}
                          >
                            <div
                              className={`step-number-inner ${
                                stage === 37 &&
                                deal[
                                  "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                                ].includes("basic") &&
                                "text-danger"
                              }`}
                            >
                              1
                            </div>
                          </div>
                        )}
                        <div className="step-body">
                          <h5 className="mb-2">Basic Information</h5>
                          <p className="fs-sm mb-0">
                            List majority owners, date founded, number of w2
                            employees, ...
                          </p>
                          {basicInfoCompleted ? (
                            <button
                              type="button"
                              className="btn btn-outline-success disabled px-3 px-xl-4 me-3 mt-2"
                            >
                              <span className="d-lg-inline">Completed</span>
                            </button>
                          ) : (
                            <button
                              id="basic-information"
                              type="button"
                              className="btn btn-primary px-3 px-xl-4 me-3 mt-2"
                              onClick={() => {
                                if (!showForm["step-1"]) {
                                  setShowForm({ ...showForm, "step-1": true });
                                } else {
                                  setShowForm({
                                    ...showForm,
                                    "step-1": false,
                                  });
                                }
                              }}
                            >
                              <span className="d-lg-inline">
                                {showForm["step-1"] ? "Close" : "Fill Out Now"}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      {/* FORM BASIC INFO */}
                      {showForm["step-1"] && (
                        <form
                          onSubmit={(e) => onSubmit(e, "step-1")}
                          ref={formRef}
                        >
                          <div className="row">
                            <div className="card shadow-lg m-0 p-0">
                              {/* List Majority Owners */}
                              <div className="bg-faded-primary p-4">
                                <h5 className="mb-1">List Majority Owners</h5>
                                <label className="fs-sm text-black">
                                  List all shareholders' first &amp; last names
                                  and their % ownership , and if they are
                                  related.{" "}
                                  <span className="d-block fs-xs">
                                    <strong className="text-black">
                                      Format Example:
                                    </strong>{" "}
                                    John Smith 35%, Jenna Smith 35% (Spouse),
                                    Austin Doe 30% (Brother in Law)
                                  </span>
                                </label>
                                <input
                                  className="form-control mt-2"
                                  type="text"
                                  id="listMajorityOwners"
                                  name="listMajorityOwners"
                                  placeholder="John Smith 35%, Jenna Smith 35% (Spouse), Austin Doe 30% (Brother in Law)"
                                  onChange={(e) => {
                                    setTempDeal({
                                      ...tempDeal,
                                      de06ce132480daa0c89600a446d4ca19aaa23dcd:
                                        e.target.value,
                                    });
                                    handleChange(e);
                                  }}
                                  onBlur={(e) => {
                                    pushBasicInformation();
                                  }}
                                  value={
                                    tempDeal.de06ce132480daa0c89600a446d4ca19aaa23dcd ||
                                    ""
                                  }
                                  required
                                />
                              </div>

                              {/* Tax Exempt Employees */}
                              <div className="bg-faded-warning p-4">
                                <h5 className="mb-1">Tax Exempt Employees</h5>
                                <label className="fs-sm text-dark">
                                  List all employees who are either tax exempt
                                  or are related to the business owners:
                                </label>

                                <div className="row">
                                  <div className="col-12 col-md-8">
                                    <ul className="fs-xs text-black p-4 p-md-5 pt-4 pb-0 pb-md-4 mt-2 form-control card-related">
                                      <li>
                                        A child or a descendant of a child;
                                      </li>
                                      <li>
                                        A brother, sister, stepbrother, or
                                        stepsister;
                                      </li>
                                      <li>
                                        The father or mother, or an ancestor of
                                        either;
                                      </li>
                                      <li>A stepfather or stepmother;</li>
                                      <li>A niece or nephew;</li>
                                      <li>An aunt or uncle;</li>
                                      <li>
                                        A son-in-law, daughter-in-law,
                                        father-in-law, mother-in-law,
                                        brother-in-law, or sister-in-law; and
                                      </li>
                                      <li>
                                        An individual (other than an individual
                                        who at any time during the tax year was
                                        the spouse of the taxpayer) who, for the
                                        tax year of the taxpayer, has the same
                                        principal place of abode as the taxpayer
                                        and is a member of the taxpayer's
                                        household.
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-12 col-md-4 mb-4 mb-md-0">
                                    <textarea
                                      className="form-control mt-2 textarea-related"
                                      type="text"
                                      id="taxExemptEmployees"
                                      name="taxExemptEmployees"
                                      placeholder="Pauline J. Gutierrez&#10;Lauri R. Francis&#10;Gordon R. Livingston&#10;Cory E. Gaddis"
                                      onChange={(e) => {
                                        setTempDeal({
                                          ...tempDeal,
                                          d628cdc49eaa25cef67830134f8b186801f5736f:
                                            e.target.value,
                                        });
                                        handleChange(e);
                                      }}
                                      onBlur={(e) => {
                                        pushBasicInformation();
                                      }}
                                      value={
                                        tempDeal.d628cdc49eaa25cef67830134f8b186801f5736f ||
                                        ""
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* Company Founded/Bought Date */}
                              <div className="bg-faded-success p-4">
                                <h5 className="mb-1">
                                  Company Founded/Bought Date
                                </h5>
                                <p className="fs-sm text-gray">
                                  Provide the date the company was founded{" "}
                                  <strong className="text-black">or</strong>{" "}
                                  when you bought the business.
                                </p>
                                <input
                                  className="form-control mt-2"
                                  style={{ maxWidth: "150px" }}
                                  type="month"
                                  id="companyFoundedYear"
                                  name="companyFoundedYear"
                                  placeholder="John Doe 35%, Catherine Doe 35%"
                                  onChange={(e) => {
                                    setTempDeal({
                                      ...tempDeal,
                                      "109d2b7f01addf730bb9155a4436a76959a1ba6a":
                                        e.target.value,
                                    });
                                    handleChange(e);
                                  }}
                                  onBlur={(e) => {
                                    pushBasicInformation();
                                  }}
                                  value={
                                    tempDeal[
                                      "109d2b7f01addf730bb9155a4436a76959a1ba6a"
                                    ] || "2015-03"
                                  }
                                  required
                                />
                              </div>

                              {deal[
                                "57d676f5141656b3316c69010f500ceea52f4eb8"
                              ] === "Restaurant" && (
                                <>
                                  {/* Facility Capacity */}
                                  <div className="bg-faded-danger p-4">
                                    <h5 className="mb-1">
                                      Restaurant Capacity
                                    </h5>
                                    <label className="text-gray">
                                      Provide the following information as it
                                      was in 2019.
                                      <span className="d-block">
                                        Any changes made after January 1st 2020
                                        should not be taken into account.
                                      </span>
                                    </label>
                                    <div className="row mt-2">
                                      <div className="col-6 col-md-3">
                                        <label className="form-label">
                                          # Indoor Tables
                                        </label>
                                        <input
                                          className="form-control"
                                          type="number"
                                          id="numberIndoorTables"
                                          name="numberIndoorTables"
                                          placeholder="18"
                                          onChange={(e) => {
                                            setTempDeal({
                                              ...tempDeal,
                                              "75779ebc9023a633bf9dcc0304b2d0c615fc9967":
                                                e.target.value,
                                            });
                                            handleChange(e);
                                          }}
                                          onBlur={(e) => {
                                            pushBasicInformation();
                                          }}
                                          value={
                                            tempDeal[
                                              "75779ebc9023a633bf9dcc0304b2d0c615fc9967"
                                            ] || ""
                                          }
                                          required
                                        />
                                      </div>
                                      <div className="col-6 col-md-3">
                                        <label className="form-label">
                                          # Indoor Seats
                                        </label>
                                        <input
                                          className="form-control"
                                          type="number"
                                          id="numberIndoorSeats"
                                          name="numberIndoorSeats"
                                          placeholder="72"
                                          onChange={(e) => {
                                            setTempDeal({
                                              ...tempDeal,
                                              "54f106286408d55c4d1c54b1fe94d6c83768387b":
                                                e.target.value,
                                            });
                                            handleChange(e);
                                          }}
                                          onBlur={(e) => {
                                            pushBasicInformation();
                                          }}
                                          value={
                                            tempDeal[
                                              "54f106286408d55c4d1c54b1fe94d6c83768387b"
                                            ] || ""
                                          }
                                          required
                                        />
                                      </div>
                                      <div className="col-6 col-md-3">
                                        <label className="form-label">
                                          # Outdoor Tables
                                        </label>
                                        <input
                                          className="form-control"
                                          type="number"
                                          id="numberOutdoorTables"
                                          name="numberOutdoorTables"
                                          placeholder="4"
                                          onChange={(e) => {
                                            setTempDeal({
                                              ...tempDeal,
                                              "1aae0d07377cd7a32141cc816325170018a21849":
                                                e.target.value,
                                            });
                                            handleChange(e);
                                          }}
                                          onBlur={(e) => {
                                            pushBasicInformation();
                                          }}
                                          value={
                                            tempDeal[
                                              "1aae0d07377cd7a32141cc816325170018a21849"
                                            ] || ""
                                          }
                                          required
                                        />
                                      </div>
                                      <div className="col-6 col-md-3">
                                        <label className="form-label">
                                          # Outdoor Seats
                                        </label>
                                        <input
                                          className="form-control"
                                          type="number"
                                          id="numberOutdoorSeats"
                                          name="numberOutdoorSeats"
                                          placeholder="16"
                                          onChange={(e) => {
                                            setTempDeal({
                                              ...tempDeal,
                                              d3aaaf197bd9488f6c3b8b285fd44077f34eb3ae:
                                                e.target.value,
                                            });
                                            handleChange(e);
                                          }}
                                          onBlur={(e) => {
                                            pushBasicInformation();
                                          }}
                                          value={
                                            tempDeal[
                                              "d3aaaf197bd9488f6c3b8b285fd44077f34eb3ae"
                                            ] || ""
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-12 col-md-6">
                                        <label className="form-label">
                                          Indoor Customer Area Size
                                        </label>
                                        <div className="input-group">
                                          <input
                                            className="form-control"
                                            type="number"
                                            id="sittingAreaSQFT"
                                            name="sittingAreaSQFT"
                                            placeholder="1850"
                                            onChange={(e) => {
                                              setTempDeal({
                                                ...tempDeal,
                                                "0eaeb6fe0438befd8fc6aee55eace0ad916a25cc":
                                                  e.target.value,
                                              });
                                              handleChange(e);
                                            }}
                                            onBlur={(e) => {
                                              pushBasicInformation();
                                            }}
                                            value={
                                              tempDeal[
                                                "0eaeb6fe0438befd8fc6aee55eace0ad916a25cc"
                                              ] || ""
                                            }
                                            required
                                          />
                                          <span className="input-group-text">
                                            Square Footage
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <label className="form-label">
                                          Kitchen Area Size
                                        </label>
                                        <div className="input-group">
                                          <input
                                            className="form-control"
                                            type="number"
                                            id="kitchenSQFT"
                                            name="kitchenSQFT"
                                            placeholder="250"
                                            onChange={(e) => {
                                              setTempDeal({
                                                ...tempDeal,
                                                e9a9ed36a18bd1ae1a044f6012a7a5acc44972b6:
                                                  e.target.value,
                                              });
                                              handleChange(e);
                                            }}
                                            onBlur={(e) => {
                                              pushBasicInformation();
                                            }}
                                            value={
                                              tempDeal[
                                                "e9a9ed36a18bd1ae1a044f6012a7a5acc44972b6"
                                              ] || ""
                                            }
                                            required
                                          />
                                          <span className="input-group-text">
                                            Square Footage
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Service Timing Comparison */}
                                  <div className="bg-secondary p-4">
                                    <h5 className="mb-1">
                                      Service Timing Comparison
                                    </h5>
                                    <div className="row">
                                      <div className="col-12 col-md-6 fs-sm">
                                        <p>
                                          During the pandemic, restaurants had
                                          to follow strict cleaning rules, which
                                          made serving customers take longer.
                                        </p>
                                        <p>
                                          For small groups (under 5 people), the
                                          average time for service used to be 50
                                          minutes. With the pandemic rules, it
                                          took an extra 7 minutes on average.
                                        </p>
                                        <p>
                                          For larger groups (over 5 people), the
                                          average time for service used to be 75
                                          minutes. With the pandemic rules, it
                                          took an extra 9 minutes on average.
                                        </p>
                                      </div>
                                      <div className="col-12 col-md-6 mt-5 mt-md-0">
                                        <ul className="list-group list-group-numbered border-0">
                                          <p className="mb-1 text-black">
                                            <strong>
                                              Disruption Examples:
                                            </strong>
                                          </p>
                                          <li className="list-group-item fs-xs text-black border-0">
                                            Seating: 5 minutes (delayed if
                                            sanitizing the table takes longer)
                                          </li>
                                          <li className="list-group-item fs-xs text-black border-0">
                                            Ordering: 10 minutes (delayed if the
                                            menu needs to be sanitized or if
                                            staff are wearing PPE){" "}
                                          </li>
                                          <li className="list-group-item fs-xs text-black border-0">
                                            Food Preparation: 20 minutes
                                            (delayed if kitchen staff are
                                            practicing extra safety measures){" "}
                                          </li>
                                          <li className="list-group-item fs-xs text-black border-0">
                                            Serving Food: 10 minutes (delayed if
                                            staff need to take extra precautions
                                            when serving food){" "}
                                          </li>
                                          <li className="list-group-item fs-xs text-black border-0">
                                            Cleaning: 10 minutes (delayed if
                                            cleaning takes longer due to
                                            pandemic protocols)
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="row">
                                        <h3 className="pb-3">
                                          Time to serve parties
                                        </h3>
                                        <div className="col-6">
                                          <h5>BEFORE Pandemic</h5>
                                          <label className="form-label">
                                            Up to 4 people
                                          </label>
                                          <div className="input-group">
                                            <input
                                              className="form-control serve-parties"
                                              type="number"
                                              id="timeToServePartiesUnderFive2019"
                                              name="timeToServePartiesUnderFive2019"
                                              placeholder="18"
                                              onChange={(e) => {
                                                setTempDeal({
                                                  ...tempDeal,
                                                  cbbf945c2005f1169274efcadcd3430ea9ab93b4:
                                                    e.target.value,
                                                });
                                                handleChange(e);
                                              }}
                                              onBlur={(e) => {
                                                pushBasicInformation();
                                              }}
                                              value={
                                                tempDeal[
                                                  "cbbf945c2005f1169274efcadcd3430ea9ab93b4"
                                                ] || ""
                                              }
                                              required
                                            />
                                            <span className="input-group-text">
                                              minutes
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <h5>DURING Pandemic</h5>
                                          <label className="form-label">
                                            Up to 4 people
                                          </label>
                                          <div className="input-group">
                                            <input
                                              className="form-control serve-parties"
                                              type="number"
                                              id="timeToServePartiesUnderFive2020"
                                              name="timeToServePartiesUnderFive2020"
                                              placeholder="4"
                                              onChange={(e) => {
                                                setTempDeal({
                                                  ...tempDeal,
                                                  "5620003b9110d793b7a6a7d4f53c9542535c17d1":
                                                    e.target.value,
                                                });
                                                handleChange(e);
                                              }}
                                              onBlur={(e) => {
                                                pushBasicInformation();
                                              }}
                                              value={
                                                tempDeal[
                                                  "5620003b9110d793b7a6a7d4f53c9542535c17d1"
                                                ] || ""
                                              }
                                              required
                                            />
                                            <span className="input-group-text">
                                              minutes
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-6 mt-3">
                                          <label className="form-label">
                                            5 people and above
                                          </label>
                                          <div className="input-group">
                                            <input
                                              className="form-control serve-parties"
                                              type="number"
                                              id="timeToServePartiesAtLeastFive2020"
                                              name="timeToServePartiesAtLeastFive2020"
                                              placeholder="16"
                                              onChange={(e) => {
                                                setTempDeal({
                                                  ...tempDeal,
                                                  "0105b8530c55c0565bc9bd4aec9a3396d8ec8f14":
                                                    e.target.value,
                                                });
                                                handleChange(e);
                                              }}
                                              onBlur={(e) => {
                                                pushBasicInformation();
                                              }}
                                              value={
                                                tempDeal[
                                                  "0105b8530c55c0565bc9bd4aec9a3396d8ec8f14"
                                                ] || ""
                                              }
                                              required
                                            />
                                            <span className="input-group-text">
                                              minutes
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-6 mt-3">
                                          <label className="form-label">
                                            5 people and above
                                          </label>
                                          <div className="input-group">
                                            <input
                                              className="form-control serve-parties"
                                              type="number"
                                              id="timeToServePartiesAtLeastFive2019"
                                              name="timeToServePartiesAtLeastFive2019"
                                              placeholder="72"
                                              onChange={(e) => {
                                                setTempDeal({
                                                  ...tempDeal,
                                                  bdda9c16bca59b0f3cae584db9eb9883c2b952c6:
                                                    e.target.value,
                                                });
                                                handleChange(e);
                                              }}
                                              onBlur={(e) => {
                                                pushBasicInformation();
                                              }}
                                              value={
                                                tempDeal[
                                                  "bdda9c16bca59b0f3cae584db9eb9883c2b952c6"
                                                ] || ""
                                              }
                                              required
                                            />
                                            <span className="input-group-text">
                                              minutes
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className="bg-white pb-5">
                                <div className="row d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="col-6 col-lg-3 mt-5 btn btn-primary"
                                  >
                                    Close and Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}

                      {/* STEP 941 */}
                      <div
                        className={`step pt-5 pb-5 ${
                          stage === 37 &&
                          deal[
                            "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                          ].includes("941") &&
                          "declined"
                        }`}
                      >
                        {nineFortyOnesUploadCompleted ? (
                          <div className="step-number bg-faded-success">
                            <div className="step-number-inner bg-success text-white h1">
                              <Check />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`step-number ${
                              stage === 37 &&
                              deal[
                                "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                              ].includes("941") &&
                              "bg-faded-danger"
                            }`}
                          >
                            <div
                              className={`step-number-inner ${
                                stage === 37 &&
                                deal[
                                  "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                                ].includes("941") &&
                                "text-danger"
                              }`}
                            >
                              2
                            </div>
                          </div>
                        )}
                        <div className="step-body">
                          <h5 className="mb-2">
                            Upload 941s{" "}
                            <span className="badge bg-faded-success text-success">
                              {number941sRequired} /{" "}
                              {nineFortyOneRequiredFiles.length}
                            </span>
                          </h5>
                          <p className="fs-sm mb-0">
                            Upload your 941s from Quarter 1 2020 through Q3
                            2021.
                          </p>

                          {nineFortyOnesUploadCompleted ? (
                            <button
                              type="button"
                              className="btn btn-outline-success disabled px-3 px-xl-4 me-3 mt-2"
                            >
                              <span className="d-lg-inline">Completed</span>
                            </button>
                          ) : (
                            <button
                              id="upload-941"
                              type="button"
                              className="btn btn-primary px-3 px-xl-4 me-3 mt-2"
                              onClick={() => {
                                if (!showForm["step-2"]) {
                                  setShowForm({ ...showForm, "step-2": true });
                                  showFileStatus();
                                } else {
                                  setShowForm({ ...showForm, "step-2": false });
                                }
                              }}
                            >
                              <span className="d-lg-inline">
                                {showForm["step-2"] ? "Close" : "Upload Files"}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      {/* FORM 941s */}
                      {showForm["step-2"] && (
                        <form
                          onSubmit={(e) => onSubmit(e, "step-2")}
                          ref={formRef}
                        >
                          <div className="row">
                            <div className="card shadow-lg m-0 p-0">
                              <div className="bg-faded-success p-4">
                                <h5 className="mb-1">
                                  941's - Employer's QUARTERLY Federal Tax
                                  Return
                                </h5>
                                <p className="fs-sm text-gray mb-0">
                                  Below is an example of a 941 form.
                                </p>
                                <div className="row">
                                  <div className="col-12">
                                    <img
                                      className="mt-4 pdf-example"
                                      src={nineFortyOneImage}
                                      alt="941 example"
                                    />
                                  </div>

                                  {/* 2020 */}
                                  <h3 className="mt-5 mb-0">2020</h3>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q1__941"
                                      className="form-label"
                                    >
                                      Quarter 1
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q1__941"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q1__941");
                                        }}
                                      />
                                      {uploading["2020-Q1__941"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q2__941"
                                      className="form-label"
                                    >
                                      Quarter 2
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q2__941"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q2__941");
                                        }}
                                      />
                                      {uploading["2020-Q2__941"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q3__941"
                                      className="form-label mt-3"
                                    >
                                      Quarter 3
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q3__941"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q3__941");
                                        }}
                                      />
                                      {uploading["2020-Q3__941"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q4__941"
                                      className="form-label mt-3"
                                    >
                                      Quarter 4
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q4__941"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q4__941");
                                        }}
                                      />
                                      {uploading["2020-Q4__941"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* 2021 */}
                                  <h3 className="mt-5 mb-0">2021</h3>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q1__941"
                                      className="form-label"
                                    >
                                      Quarter 1
                                    </label>

                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q1__941"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q1__941");
                                        }}
                                      />
                                      {uploading["2021-Q1__941"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q2__941"
                                      className="form-label"
                                    >
                                      Quarter 2
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q2__941"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q2__941");
                                        }}
                                      />
                                      {uploading["2021-Q2__941"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q3__941"
                                      className="form-label mt-3"
                                    >
                                      Quarter 3
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q3__941"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q3__941");
                                        }}
                                      />
                                      {uploading["2021-Q3__941"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="bg-white pb-5">
                                <div className="row d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="col-5 mt-5 btn btn-primary"
                                  >
                                    Close and Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}

                      {/* STEP WAGES */}
                      <div
                        className={`step pt-5 pb-5 ${
                          stage === 37 &&
                          deal[
                            "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                          ].includes("WAGES") &&
                          "declined"
                        }`}
                      >
                        {wagesUploadCompleted ? (
                          <div className="step-number bg-faded-success">
                            <div className="step-number-inner bg-success text-white h1">
                              <Check />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`step-number ${
                              stage === 37 &&
                              deal[
                                "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                              ].includes("WAGES") &&
                              "bg-faded-danger"
                            }`}
                          >
                            <div
                              className={`step-number-inner ${
                                stage === 37 &&
                                deal[
                                  "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                                ].includes("WAGES") &&
                                "text-danger"
                              }`}
                            >
                              3
                            </div>
                          </div>
                        )}
                        <div className="step-body">
                          <h5 className="mb-2">
                            Upload Employee Wages{" "}
                            <span className="badge bg-faded-success text-success">
                              {numberWagesRequired} /{" "}
                              {wagesRequiredFiles.length}
                            </span>
                          </h5>
                          <p className="fs-sm mb-0">
                            Provide Quarterly Detailed Employee Wages.
                          </p>

                          {wagesUploadCompleted ? (
                            <button
                              type="button"
                              className="btn btn-outline-success disabled px-3 px-xl-4 me-3 mt-2"
                            >
                              <span className="d-lg-inline">Completed</span>
                            </button>
                          ) : (
                            <button
                              id="upload-employee-wages"
                              type="button"
                              className="btn btn-primary px-3 px-xl-4 me-3 mt-2"
                              onClick={() => {
                                if (!showForm["step-3"]) {
                                  setShowForm({ ...showForm, "step-3": true });
                                  showFileStatus();
                                } else {
                                  setShowForm({ ...showForm, "step-3": false });
                                }
                              }}
                            >
                              <span className="d-lg-inline">
                                {showForm["step-3"] ? "Close" : "Upload Files"}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      {/* FORM WAGES */}
                      {showForm["step-3"] && (
                        <form
                          onSubmit={(e) => onSubmit(e, "step-3")}
                          ref={formRef}
                        >
                          <div className="row">
                            <div className="card shadow-lg m-0 p-0">
                              {/* WAGES */}
                              <div className="bg-faded-warning p-4">
                                <h5 className="mb-1">
                                  Quarterly Employee Detail Wages - Pit Wages
                                </h5>
                                <p className="text-dark mb-0">
                                  Below is an example of a quarterly employee
                                  detail wage form. Make sure all the wages add
                                  up with your 941s section 2 or 5a Column 1 for
                                  each quarter.
                                </p>
                                <div className="row">
                                  <div className="col-12">
                                    <img
                                      className="mt-4 pdf-example"
                                      src={quarterlyWagesImage}
                                      alt="employee wages example"
                                    />
                                  </div>

                                  {/* 2020 */}
                                  <h3 className="mt-5 mb-0">2020</h3>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q1__WAGES"
                                      className="form-label"
                                    >
                                      Quarter 1
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q1__WAGES"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q1__WAGES");
                                        }}
                                      />
                                      {uploading["2020-Q1__WAGES"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q2__WAGES"
                                      className="form-label"
                                    >
                                      Quarter 2
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q2__WAGES"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q2__WAGES");
                                        }}
                                      />
                                      {uploading["2020-Q2__WAGES"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q3__WAGES"
                                      className="form-label mt-3"
                                    >
                                      Quarter 3
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q3__WAGES"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q3__WAGES");
                                        }}
                                      />
                                      {uploading["2020-Q3__WAGES"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q4__WAGES"
                                      className="form-label mt-3"
                                    >
                                      Quarter 4
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q4__WAGES"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q4__WAGES");
                                        }}
                                      />
                                      {uploading["2020-Q4__WAGES"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* 2021 */}
                                  <h3 className="mt-5 mb-0">2021</h3>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q1__WAGES"
                                      className="form-label"
                                    >
                                      Quarter 1
                                    </label>

                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q1__WAGES"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q1__WAGES");
                                        }}
                                      />
                                      {uploading["2021-Q1__WAGES"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q2__WAGES"
                                      className="form-label"
                                    >
                                      Quarter 2
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q2__WAGES"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q2__WAGES");
                                        }}
                                      />
                                      {uploading["2021-Q2__WAGES"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q3__WAGES"
                                      className="form-label mt-3"
                                    >
                                      Quarter 3
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q3__WAGES"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q3__WAGES");
                                        }}
                                      />
                                      {uploading["2021-Q3__WAGES"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="bg-white pb-5">
                                <div className="row d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="col-5 mt-5 btn btn-primary"
                                  >
                                    Close and Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}

                      {/* STEP P&L */}
                      <div
                        className={`step pt-5 pb-5 ${
                          stage === 37 &&
                          deal[
                            "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                          ].includes("P&L") &&
                          "declined"
                        }`}
                      >
                        {profitLossUploadCompleted ? (
                          <div className="step-number bg-faded-success">
                            <div className="step-number-inner bg-success text-white h1">
                              <Check />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`step-number ${
                              stage === 37 &&
                              deal[
                                "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                              ].includes("P&L") &&
                              "bg-faded-danger"
                            }`}
                          >
                            <div
                              className={`step-number-inner ${
                                stage === 37 &&
                                deal[
                                  "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                                ].includes("P&L") &&
                                "text-danger"
                              }`}
                            >
                              4
                            </div>
                          </div>
                        )}
                        <div className="step-body">
                          <h5 className="mb-2">
                            Upload Profit and Loss Reports{" "}
                            <span className="badge bg-faded-success text-success">
                              {numberProfitLossRequired} /{" "}
                              {profitLossRequiredFiles.length}
                            </span>
                          </h5>
                          <p className="fs-sm mb-0">
                            Make sure your P&Ls are reported as Cash Basis.
                          </p>

                          {profitLossUploadCompleted ? (
                            <button
                              type="button"
                              className="btn btn-outline-success disabled px-3 px-xl-4 me-3 mt-2"
                            >
                              <span className="d-lg-inline">Completed</span>
                            </button>
                          ) : (
                            <button
                              id="upload-p&l"
                              type="button"
                              className="btn btn-primary px-3 px-xl-4 me-3 mt-2"
                              onClick={() => {
                                if (!showForm["step-4"]) {
                                  setShowForm({ ...showForm, "step-4": true });
                                  showFileStatus();
                                } else {
                                  setShowForm({ ...showForm, "step-4": false });
                                }
                              }}
                            >
                              <span className="d-lg-inline">
                                {showForm["step-4"] ? "Close" : "Upload Files"}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      {/* FORM P&L */}
                      {showForm["step-4"] && (
                        <form
                          onSubmit={(e) => onSubmit(e, "step-4")}
                          ref={formRef}
                        >
                          <div className="row">
                            <div className="card shadow-lg m-0 p-0">
                              <div className="bg-faded-warning p-4">
                                <h5 className="mb-1">
                                  Profit &amp; Loss Reports in Cash Basis
                                </h5>
                                <p className="text-dark mb-0">
                                  Below is an example of a profit and loss
                                  report for quarter 1 2019. You'll have to
                                  upload each quarter using the form below and
                                  be sure that all your P&Ls are set to cash
                                  basis instead of accrual basis.
                                </p>
                                <div className="row">
                                  <div className="col-12">
                                    <img
                                      className="mt-4 pdf-example"
                                      src={profitAndLossImage}
                                      alt="employee wages example"
                                    />
                                  </div>

                                  {/* 2019 */}
                                  <h3 className="mt-5 mb-0">2019</h3>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2019-Q1__PL"
                                      className="form-label"
                                    >
                                      Quarter 1
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2019-Q1__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2019-Q1__PL");
                                        }}
                                      />
                                      {uploading["2019-Q1__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2019-Q2__PL"
                                      className="form-label"
                                    >
                                      Quarter 2
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2019-Q2__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2019-Q2__PL");
                                        }}
                                      />
                                      {uploading["2019-Q2__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2019-Q3__PL"
                                      className="form-label mt-3"
                                    >
                                      Quarter 3
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2019-Q3__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2019-Q3__PL");
                                        }}
                                      />
                                      {uploading["2019-Q3__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2019-Q4__PL"
                                      className="form-label mt-3"
                                    >
                                      Quarter 4
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2019-Q4__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2019-Q4__PL");
                                        }}
                                      />
                                      {uploading["2019-Q4__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* 2020 */}
                                  <h3 className="mt-5 mb-0">2020</h3>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q1__PL"
                                      className="form-label"
                                    >
                                      Quarter 1
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q1__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q1__PL");
                                        }}
                                      />
                                      {uploading["2020-Q1__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q2__PL"
                                      className="form-label"
                                    >
                                      Quarter 2
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q2__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q2__PL");
                                        }}
                                      />
                                      {uploading["2020-Q2__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q3__PL"
                                      className="form-label mt-3"
                                    >
                                      Quarter 3
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q3__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q3__PL");
                                        }}
                                      />
                                      {uploading["2020-Q3__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020-Q4__PL"
                                      className="form-label mt-3"
                                    >
                                      Quarter 4
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020-Q4__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020-Q4__PL");
                                        }}
                                      />
                                      {uploading["2020-Q4__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* 2021 */}
                                  <h3 className="mt-5 mb-0">2021</h3>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q1__PL"
                                      className="form-label"
                                    >
                                      Quarter 1
                                    </label>

                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q1__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q1__PL");
                                        }}
                                      />
                                      {uploading["2021-Q1__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q2__PL"
                                      className="form-label"
                                    >
                                      Quarter 2
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q2__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q2__PL");
                                        }}
                                      />
                                      {uploading["2021-Q2__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021-Q3__PL"
                                      className="form-label mt-3"
                                    >
                                      Quarter 3
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021-Q3__PL"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021-Q3__PL");
                                        }}
                                      />
                                      {uploading["2021-Q3__PL"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="bg-white pb-5">
                                <div className="row d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="col-5 mt-5 btn btn-primary"
                                  >
                                    Close and Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}

                      {/* STEP PPP */}
                      <div
                        className={`step pt-5 pb-5 ${
                          stage === 37 &&
                          deal[
                            "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                          ].includes("PPP") &&
                          "declined"
                        }`}
                      >
                        {PPPUploadCompleted ? (
                          <div className="step-number bg-faded-success">
                            <div className="step-number-inner bg-success text-white h1">
                              <Check />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`step-number ${
                              stage === 37 &&
                              deal[
                                "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                              ].includes("PPP") &&
                              "bg-faded-danger"
                            }`}
                          >
                            <div
                              className={`step-number-inner ${
                                stage === 37 &&
                                deal[
                                  "98c4b8192243782bd2ea88da5eaf1935629bb65d"
                                ].includes("PPP") &&
                                "text-danger"
                              }`}
                            >
                              5
                            </div>
                          </div>
                        )}
                        <div className="step-body">
                          <h5 className="mb-2">
                            Upload PPP Documents{" "}
                            <span className="badge bg-faded-success text-success">
                              {numberPPPRequired} / {PPPRequiredFiles.length}
                            </span>
                          </h5>
                          <p className="fs-sm mb-0">
                            Provide your PPP 3508S documents and loan details
                          </p>

                          {PPPUploadCompleted ? (
                            <button
                              type="button"
                              className="btn btn-outline-success disabled px-3 px-xl-4 me-3 mt-2"
                            >
                              <span className="d-lg-inline">Completed</span>
                            </button>
                          ) : (
                            <button
                              id="upload-ppp"
                              type="button"
                              className="btn btn-primary px-3 px-xl-4 me-3 mt-2"
                              onClick={() => {
                                if (!showForm["step-5"]) {
                                  setShowForm({ ...showForm, "step-5": true });
                                  showFileStatus();
                                } else {
                                  setShowForm({ ...showForm, "step-5": false });
                                }
                              }}
                            >
                              <span className="d-lg-inline">
                                {showForm["step-5"] ? "Close" : "Upload Files"}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      {/* FORM PPP */}
                      {showForm["step-5"] && (
                        <form
                          onSubmit={(e) => onSubmit(e, "step-5")}
                          ref={formRef}
                        >
                          <div className="row">
                            <div className="card shadow-lg m-0 p-0">
                              {/* WAGES */}
                              <div className="bg-faded-warning p-4">
                                <h5 className="mb-1">
                                  PPP Loan Forgiveness Application Form
                                </h5>
                                <p className="text-dark mb-0">
                                  Upload all 3508S forms. The 3508S form is a
                                  PPP loan forgiveness application that contains
                                  the covered period as well as the amount that
                                  was forgiven.
                                </p>
                                <div className="row">
                                  <div className="col-12">
                                    <img
                                      className="mt-4 pdf-example"
                                      src={pppImage}
                                      alt="employee wages example"
                                    />
                                  </div>

                                  <h3 className="mt-5 mb-0">3508S Form</h3>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2020__PPP"
                                      className="form-label"
                                    >
                                      First Draw
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2020__PPP"
                                        onChange={(e) => {
                                          uploadFiles(e, "2020__PPP");
                                        }}
                                      />
                                      {uploading["2020__PPP"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlFor="2021__PPP"
                                      className="form-label"
                                    >
                                      Second Draw
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="Y2021__PPP"
                                        onChange={(e) => {
                                          uploadFiles(e, "2021__PPP");
                                        }}
                                      />
                                      {uploading["2021__PPP"] && (
                                        <div className="uploading">
                                          <span className="spinner-border spinner-border-sm"></span>{" "}
                                          Uploading
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="bg-white pb-5">
                                <div className="row d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="col-5 mt-5 btn btn-primary"
                                  >
                                    Close and Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}

                      {/* STEP AGREEMENT */}
                      <div className="step pt-5 pb-5">
                        {contractSigned ? (
                          <div className="step-number bg-faded-success">
                            <div className="step-number-inner bg-success text-white h1">
                              <Check />
                            </div>
                          </div>
                        ) : (
                          <div className="step-number">
                            <div className="step-number-inner">6</div>
                          </div>
                        )}
                        <div className="step-body">
                          <h5 className="mb-2">Review Agreement</h5>
                          {contractSigned ? (
                            <p className="fs-sm mb-0">Agreement Signed</p>
                          ) : (
                            <p className="fs-sm mb-0">
                              Fill out and sign the agreement with us in order
                              to proceed with your refund.
                            </p>
                          )}

                          {!contractSigned && (
                            <button
                              id="create-agreement"
                              type="button"
                              className="btn btn-primary px-3 px-xl-4 me-3 mt-2"
                              onClick={() => {
                                if (!showForm["step-6"]) {
                                  setShowForm({ ...showForm, "step-6": true });
                                  createAgreement();
                                } else {
                                  setShowForm({ ...showForm, "step-6": false });
                                }
                              }}
                            >
                              <span className="d-lg-inline">
                                {showForm["step-6"]
                                  ? "Close"
                                  : "Open Agreement"}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      {/* FORM AGREEMENT */}
                      {showForm["step-6"] && (
                        <form onSubmit={(e) => onSubmit(e, "step-6")}>
                          <div className="row">
                            <div className="card shadow-lg m-0 p-0">
                              {contractURL ? (
                                <div className="p-4 pb-0 mb-0">
                                  <h5 className="mb-3">Fill out and Sign</h5>

                                  <div className="row pb-0 mb-0">
                                    <div className="col-12 pb-0 mb-0">
                                      <iframe
                                        className="agreement"
                                        src={contractURL}
                                        title="Pandadoc Agreement"
                                      ></iframe>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="row p-5">
                                  <h4 className="text-center">
                                    Preparing Agreement
                                  </h4>
                                  <div
                                    className="progress-animation progress mb-3"
                                    style={{
                                      maxWidth: "350px",
                                      margin: "auto",
                                      padding: "0",
                                    }}
                                  >
                                    <div
                                      className="progress-bar slow bg-gradient-primary"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      )}

                      {/* STEP SUBMIT */}
                      <div className="step pt-5 pb-5">
                        <div className="step-number">
                          <div className="step-number-inner">7</div>
                        </div>
                        <div className="step-body">
                          <h5 className="mb-2">Submit your application</h5>
                          <p className="fs-sm mb-0">
                            Once you have uploaded all your files and signed the
                            agreement, you can submit your case to be reviewed
                            by one of our ERC specialist.
                          </p>

                          {contractSigned ? (
                            <button
                              id="submit-application"
                              type="button"
                              className="btn btn-primary px-3 px-xl-4 me-3 mt-2"
                              onClick={() => {
                                updateStage(35);
                              }}
                            >
                              <span className="d-lg-inline">
                                Submit Application Now!
                              </span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary disabled px-3 px-xl-4 me-3 mt-2"
                            >
                              <span className="d-lg-inline">
                                Complete Step 6 to Submit
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {(stage === 35 || stage === 36) && (
                    <div className="row">
                      <h1 className="h2">Documents In Review</h1>
                      <p className="fs-6 mb-4 stage-description">
                        Your application has been submitted for review. We
                        appreciate your patience while we thoroughly examine the
                        information you have provided.
                      </p>
                      <Player
                        className="animated-icon"
                        src={docsInReviewImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                </>
              )}

              {/* PROCESSING STAGE */}
              {pipeline === 2 && (
                <>
                  <h1 className="h2">In Progress</h1>
                  {stage === 9 && (
                    <div className="row">
                      <h2 className="h4">Processing</h2>
                      <p className="fs-6 mb-4 stage-description">
                        Congratulations! Your documents have been approved.
                        We're now working diligently to analyze and process your
                        data.
                      </p>
                      <Player
                        className="animated-icon"
                        src={inProgressProcessingImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {stage === 10 && (
                    <div className="row">
                      <h2 className="h4">Copywriting</h2>
                      <p className="fs-6 mb-4 stage-description">
                        We are pleased to inform you that our analysis of your
                        data is complete. Our team is now working on creating a
                        comprehensive report that summarizes your information,
                        which will be submitted to the IRS.
                      </p>
                      <Player
                        className="animated-icon"
                        src={inProgressCopywritingImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                </>
              )}

              {/* FINALIZING STAGE */}
              {pipeline === 10 && (
                <>
                  <h1 className="h2">Finalizing</h1>
                  {/* Sign the Invoice */}
                  {stage === 61 && (
                    <div className="row">
                      <h2 className="h4">Sign the Invoice</h2>
                      <p className="fs-6 mb-4 stage-description">
                        We have completed the report for your ERTC application.
                        To move forward with the submission process, please sign
                        the invoice below.
                      </p>

                      <div className="steps steps-sm">
                        {/* STEP INVOICE */}
                        <div className="step pt-5 pb-5">
                          {invoiceSigned ? (
                            <div className="step-number bg-faded-success">
                              <div className="step-number-inner bg-success text-white h1">
                                <Check />
                              </div>
                            </div>
                          ) : (
                            <div className="step-number">
                              <div className="step-number-inner">1</div>
                            </div>
                          )}
                          <div className="step-body">
                            <h5 className="mb-2">Review Agreement</h5>
                            <p className="fs-sm mb-0">
                              Open and fill out the invoice.
                            </p>

                            {!invoiceSigned && (
                              <button
                                type="button"
                                className="btn btn-primary px-3 px-xl-4 me-3 mt-2"
                                onClick={() => {
                                  if (!showForm["step-invoice"]) {
                                    setShowForm({
                                      ...showForm,
                                      "step-invoice": true,
                                    });
                                    createInvoice();
                                  } else {
                                    setShowForm({
                                      ...showForm,
                                      "step-invoice": false,
                                    });
                                  }
                                }}
                              >
                                Open Invoice
                              </button>
                            )}
                          </div>
                        </div>
                        {/* FORM INVOICE */}
                        {showForm["step-invoice"] && (
                          <form onSubmit={(e) => onSubmit(e, "step-invoice")}>
                            <div className="row">
                              <div className="card shadow-lg m-0 p-0">
                                {invoiceURL ? (
                                  <div className="p-4 pb-0 mb-0">
                                    <h5 className="mb-3">Fill out and Sign</h5>

                                    <div className="row pb-0 mb-0">
                                      <div className="col-12 pb-0 mb-0">
                                        <iframe
                                          className="agreement"
                                          src={invoiceURL}
                                          title="Pandadoc Agreement"
                                        ></iframe>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row p-5">
                                    <h4 className="text-center">
                                      Preparing Invoice
                                    </h4>
                                    <div
                                      className="progress-animation progress mb-3"
                                      style={{
                                        maxWidth: "350px",
                                        margin: "auto",
                                        padding: "0",
                                      }}
                                    >
                                      <div
                                        className="progress-bar slow bg-gradient-primary"
                                        role="progressbar"
                                        style={{ width: "0%" }}
                                      ></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                      <Player
                        className="animated-icon"
                        src={finalizingInvoiceImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {/* Sign the 8821 Form! */}
                  {stage === 62 && (
                    <div className="row">
                      {showFileStatus()}
                      <h2 className="h4">Sign the 8821 Form!</h2>
                      <p className="fs-6 fs-sm mb-4 stage-description">
                        Thank you for signing the invoice! The next step in the
                        process is for you to sign the 8821 IRS form. This will
                        allow us to keep you updated and ensure a smooth flow of
                        communication with the IRS. You'll receive the 8821 form
                        in your email within the next business day.
                        <br />
                        <br />
                        Once you receive your 8821 form, sign it and upload a
                        picture of it below. (You can use your phone to take a
                        picture and upload it.)
                      </p>
                      <div className="stage-description">
                        <div className="col-12">
                          <label htmlFor="8821__signed" className="form-label">
                            <strong>Upload Signed 8821</strong>
                          </label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              type="file"
                              id="Y8821__signed"
                              onChange={(e) => {
                                uploadFiles(e, "8821__signed");
                              }}
                            />
                            {uploading["8821__signed"] && (
                              <div className="uploading">
                                <span className="spinner-border spinner-border-sm"></span>{" "}
                                Uploading
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <Player
                        className="animated-icon"
                        src={finalizing8821Image}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {/* Download 941s and Letter of Opinion */}
                  {stage === 59 && (
                    <div className="row">
                      <h2 className="h4">
                        Download 941s and Letter of Opinion
                      </h2>
                      <p className="fs-6 mb-4 stage-description">
                        You have reached the final stage of the ERTC application
                        process. In order to complete this process, please check
                        your email for the documents that you will need to send
                        to the IRS.
                        <br />
                        <br />
                        In the email, you will find six 941-X forms, the ERC
                        Letter of Opinion, and detailed instructions on where to
                        mail the necessary items. We kindly request your prompt
                        attention to this matter to ensure a smooth and
                        efficient process.
                      </p>
                      <div className="stage-description">
                        <label className="fs-sm text-black">
                          <strong>Provide USPS Tracking Number</strong>
                        </label>
                        <div className="row">
                          <div className="col-6">
                            <Cleave
                              className="form-control tracking-number"
                              type="text"
                              id="trackingNumber"
                              name="trackingNumber"
                              placeholder="7231 2345 1234 2524"
                              minLength="19"
                              maxLength="19"
                              onChange={(e) => {
                                setTempDeal({
                                  ...tempDeal,
                                  d98d7e807aa02cf52be183fd074ca9b9132729c4:
                                    e.target.value,
                                });
                                handleChangeTracking(e);
                              }}
                              onBlur={(e) => {
                                pushTrackingNumber();
                              }}
                              value={
                                tempDeal[
                                  "d98d7e807aa02cf52be183fd074ca9b9132729c4"
                                ] || ""
                              }
                              required
                              options={{
                                delimiter: " ",
                                blocks: [4, 4, 4, 4],
                              }}
                            ></Cleave>
                          </div>
                          <div className="col-6">
                            <button
                              className={`btn btn-primary ${
                                !trackingButton && "disabled"
                              }`}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      <Player
                        className="animated-icon"
                        src={finalizing8821Image}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {/* Missing Tracking Number */}
                  {stage === 60 && (
                    <div className="steps steps-sm">
                      {/* STEP TRACKING NUMBER */}
                      <div className="row">
                        <h2 className="h4">Missing Tracking Number</h2>
                        <p className="fs-6 mb-4 stage-description">
                          We need the tracking number for your mailed documents.
                          Please take a moment to enter the tracking number in
                          the designated section below.
                        </p>
                        <div className="row">
                          <div className="col-6">
                            <Cleave
                              className="form-control tracking-number"
                              type="text"
                              id="trackingNumber"
                              name="trackingNumber"
                              placeholder="7231 2345 1234 2524"
                              minLength="19"
                              maxLength="19"
                              onChange={(e) => {
                                setTempDeal({
                                  ...tempDeal,
                                  d98d7e807aa02cf52be183fd074ca9b9132729c4:
                                    e.target.value,
                                });
                                handleChangeTracking(e);
                              }}
                              onBlur={(e) => {
                                pushTrackingNumber();
                              }}
                              value={
                                tempDeal[
                                  "d98d7e807aa02cf52be183fd074ca9b9132729c4"
                                ] || ""
                              }
                              required
                              options={{
                                delimiter: " ",
                                blocks: [4, 4, 4, 4],
                              }}
                            ></Cleave>
                          </div>
                          <div className="col-6">
                            <button
                              className={`btn btn-primary ${
                                !trackingButton && "disabled"
                              }`}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      <Player
                        className="animated-icon"
                        src={finalizingTrackingNumberImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                </>
              )}

              {/* FILED STAGE */}
              {pipeline === 3 && (
                <>
                  <h1 className="h2">Filed</h1>
                  {stage === 14 && (
                    <div className="row">
                      <h2 className="h4">
                        Expected date of arrival{" "}
                        {deal.rotten_time &&
                          format(
                            new Date(deal.rotten_time.split(" ")[0]),
                            "MM/dd/yy"
                          )}
                      </h2>
                      <p className="fs-6 mb-4 stage-description">
                        Your package is on its way! We will keep a close eye on
                        its progress and let you know as soon as it has been
                        delivered.
                      </p>
                      <Player
                        className="animated-icon"
                        src={filedInTransitImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {stage === 55 && (
                    <div className="row">
                      <h2 className="h4">
                        Delivered!{" "}
                        {deal.rotten_time &&
                          format(
                            new Date(deal.rotten_time.split(" ")[0]),
                            "dd/MM/yy"
                          )}
                      </h2>
                      <p className="fs-6 mb-4 stage-description">
                        Your ERTC application has been successfully delivered to
                        the IRS. Please note that it may take up to 6 weeks for
                        the IRS to scan and process your documents. Our team
                        will be closely monitoring the progress and will keep
                        you informed of any updates.
                      </p>
                      <Player
                        className="animated-icon"
                        src={filedDeliveredImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {stage === 15 && (
                    <div className="row">
                      <h2 className="h4">Scanned</h2>
                      <p className="fs-sm text-dark">
                        <strong>
                          Expected date to get processed{" "}
                          {deal.rotten_time &&
                            format(
                              new Date(deal.rotten_time.split(" ")[0]),
                              "dd/MM/yy"
                            )}
                        </strong>
                      </p>
                      <p className="fs-6 mb-4 stage-description">
                        We're pleased to inform you that the IRS has
                        successfully scanned your documents! From here, it will
                        take 16-20 weeks for the scanned quarters to be
                        processed. Please Keep In mind that it may take longer.
                        <br />
                        <br />
                        If only a portion has been scanned, please keep checking
                        in for updates over the next few weeks.
                      </p>
                      <Player
                        className="animated-icon"
                        src={filedScanningImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {stage === 16 && (
                    <div className="row">
                      <h2 className="h4">Processing</h2>
                      <p className="fs-sm text-dark">
                        <strong>
                          Expected date of completion{" "}
                          {deal.rotten_time &&
                            format(
                              new Date(deal.rotten_time.split(" ")[0]),
                              "dd/MM/yy"
                            )}
                        </strong>
                      </p>
                      <p className="fs-6 mb-4 stage-description">
                        Great news! One or more of your quarters have been
                        processed by the IRS. Keep an eye out for a notice with
                        your refund amount in the coming weeks.
                        <br />
                        <br />
                        If only a portion has been processed, please keep
                        checking in for updates over the next few weeks.
                      </p>
                      <Player
                        className="animated-icon"
                        src={filedProcessingImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                </>
              )}

              {/* TRACE STAGE */}
              {pipeline === 4 && (
                <>
                  <h1 className="h2">Trace 3911</h1>
                  {stage === 52 && (
                    <div className="row">
                      <h2 className="h4">Preparing Documents</h2>
                      <p className="fs-6 mb-4 stage-description">
                        We're working to find your missing ERTC refund checks.
                        The next step is an IRS 3911 Trace. Please check your
                        email for the necessary forms for this process. Kindly
                        review, sign and scan the forms back to us promptly. We
                        will then forward them to the IRS to begin the trace
                        process.
                      </p>
                      <Player
                        className="animated-icon"
                        src={tracePreparingImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {stage === 51 && (
                    <div className="row">
                      <h2 className="h4">In Transit</h2>
                      <p className="fs-sm text-dark">
                        <strong>
                          Expected date of completion{" "}
                          {format(
                            new Date(deal.rotten_time.split(" ")[0]),
                            "dd/MM/yy"
                          )}
                        </strong>
                      </p>
                      <p className="fs-6 mb-4 stage-description">
                        We have successfully faxed your forms to the IRS for the
                        3911 Trace process. Please note that it typically takes
                        up to 6 weeks for the IRS to begin processing the trace.
                        Our team will continue to monitor the status and update
                        you as soon as we receive any updates.
                      </p>
                      <Player
                        className="animated-icon"
                        src={traceInTransitImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {stage === 23 && (
                    <div className="row">
                      <h2 className="h4">In Progress</h2>
                      <p className="fs-sm text-dark">
                        <strong>
                          Expected date of completion{" "}
                          {format(
                            new Date(deal.rotten_time.split(" ")[0]),
                            "dd/MM/yy"
                          )}
                        </strong>
                      </p>
                      <p className="fs-6 mb-4 stage-description">
                        We are pleased to inform you that your trace refund has
                        been initiated and is now underway. Please allow the IRS
                        a few weeks to complete the trace process.
                      </p>
                      <Player
                        className="animated-icon"
                        src={traceInProgressImage}
                        background="transparent"
                        speed="0.5"
                        loop
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {stage === 24 && (
                    <div className="row">
                      <h2 className="h4">Complete</h2>
                      <p className="fs-sm text-dark"></p>
                      <p className="fs-6 mb-4 stage-description">
                        The IRS has successfully completed the trace refund
                        process. You can expect to receive your missing checks
                        in the mail over the next few weeks.
                      </p>
                      <Player
                        className="animated-icon"
                        src={traceCompleteImage}
                        background="transparent"
                        speed="0.5"
                        autoplay
                      ></Player>
                    </div>
                  )}
                </>
              )}

              {/* AMENDEMENT STAGE */}
              {pipeline === 9 && (
                <>
                  <h1 className="h2">Amendement</h1>
                  {stage === 47 && (
                    <div className="row">
                      <h2 className="h4">Processing</h2>
                      <p className="fs-sm text-dark"></p>
                      <p className="fs-6 mb-4 stage-description">
                        We've noticed a delay in the processing of your ERTC
                        application. Our team is taking action to re-file it
                        with the IRS. Potential reasons include loss in mail or
                        outdated information. We apologize for the inconvenience
                        and are working promptly to update and resend the
                        necessary documents with re-mailing instructions.
                      </p>
                      <Player
                        className="animated-icon"
                        src={amendmentProcessingImage}
                        background="transparent"
                        speed="0.5"
                        autoplay
                      ></Player>
                    </div>
                  )}
                  {stage === 48 && (
                    <div className="row">
                      <h2 className="h4">Copywriting</h2>
                      <p className="fs-sm text-dark"></p>
                      <p className="fs-6 mb-4 stage-description">
                        Our team is currently working on updating the necessary
                        documents and will shortly send you an email with the
                        updated documents and re-filing instructions.
                      </p>
                      <Player
                        className="animated-icon"
                        src={amemdmentCopywritingImage}
                        background="transparent"
                        speed="0.5"
                        autoplay
                      ></Player>
                    </div>
                  )}
                </>
              )}

              {/* NOTICES STAGE */}
              {pipeline === 7 && (
                <>
                  {showFileStatus()}
                  <h1 className="h2">Notices, Checks, and Payments</h1>
                  <div className="row">
                    <p className="fs-sm text-dark"></p>
                    <p className="fs-6 mb-4 stage-description">
                      Congratulations! We are pleased to inform you that your
                      ERTC application has been processed by the IRS and they
                      have sent out notices for either all or partial quarters.
                      You can keep track of the notices and refund amounts in
                      the designated section below.
                      <br />
                      <br />
                      When you receive a notice, upload them using your phone
                      below!
                    </p>
                  </div>
                  <h3>2020</h3>
                  <div className="d-sm-flex mb-4">
                    <div className="col-4 card border-faded-primary m-1">
                      <div className="card-body">
                        <h5 className="card-title">Quarter 2</h5>
                        <div className="p-1 fs-xs text-info">
                          {deal["f7affa183457bc63c640ee3515b5042a03025d49"]
                            ? deal["f7affa183457bc63c640ee3515b5042a03025d49"]
                            : "pending"}
                        </div>
                        <div className="badge bg-faded-success text-success fs-5">
                          {deal["04f24fa64d3b15364c6c9d5b2d362d1bf50429cb"]
                            ? deal[
                                "04f24fa64d3b15364c6c9d5b2d362d1bf50429cb"
                              ].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : "pending"}
                        </div>

                        <div className="position-relative mt-3">
                          <div className="fs-sm">Upload Notice</div>
                          <input
                            className="form-control"
                            type="file"
                            id="Y2020-Q2__NOTICE"
                            onChange={(e) => {
                              uploadFiles(e, "2020-Q2__NOTICE");
                            }}
                          />
                          {uploading["2020-Q2__NOTICE"] && (
                            <div className="uploading">
                              <span className="spinner-border spinner-border-sm"></span>{" "}
                              Uploading
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-4 card border-faded-primary m-1">
                      <div className="card-body">
                        <h5 className="card-title">Quarter 3</h5>
                        <div className="p-1 fs-xs text-info">
                          {deal["9cd4b27295bc3363ae7fa9d92a776f47f297801d"]
                            ? deal["9cd4b27295bc3363ae7fa9d92a776f47f297801d"]
                            : "pending"}
                        </div>
                        <div className="badge bg-faded-success text-success fs-5">
                          {deal["e48972799189d048c2d86a7a737056381f980a8a"]
                            ? deal[
                                "e48972799189d048c2d86a7a737056381f980a8a"
                              ].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : "pending"}
                        </div>

                        <div className="position-relative mt-3">
                          <div className="fs-sm">Upload Notice</div>
                          <input
                            className="form-control"
                            type="file"
                            id="Y2020-Q3__NOTICE"
                            onChange={(e) => {
                              uploadFiles(e, "2020-Q3__NOTICE");
                            }}
                          />
                          {uploading["2020-Q3__NOTICE"] && (
                            <div className="uploading">
                              <span className="spinner-border spinner-border-sm"></span>{" "}
                              Uploading
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-4 card border-faded-primary m-1">
                      <div className="card-body">
                        <h5 className="card-title">Quarter 4</h5>
                        <div className="p-1 fs-xs text-info">
                          {deal["c3cf205df4002906dcc71fef1f3cf23bdd59228b"]
                            ? deal["c3cf205df4002906dcc71fef1f3cf23bdd59228b"]
                            : "pending"}
                        </div>
                        <div className="badge bg-faded-success text-success fs-5">
                          {deal["147f1b99b0c644c772a760501ab5b1c2459a3f3c"]
                            ? deal[
                                "147f1b99b0c644c772a760501ab5b1c2459a3f3c"
                              ].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : "pending"}
                        </div>

                        <div className="position-relative mt-3">
                          <div className="fs-sm">Upload Notice</div>
                          <input
                            className="form-control"
                            type="file"
                            id="Y2020-Q4__NOTICE"
                            onChange={(e) => {
                              uploadFiles(e, "2020-Q4__NOTICE");
                            }}
                          />
                          {uploading["2020-Q4__NOTICE"] && (
                            <div className="uploading">
                              <span className="spinner-border spinner-border-sm"></span>{" "}
                              Uploading
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <h3>2021</h3>
                  <div className="d-sm-flex mb-4">
                    <div className="col-4 card border-faded-primary m-1">
                      <div className="card-body">
                        <h5 className="card-title">Quarter 1</h5>
                        <div className="p-1 fs-xs text-info">
                          {deal["b4021f2f93848412bd45f9fdf057aab8d7d1149e"]
                            ? deal["b4021f2f93848412bd45f9fdf057aab8d7d1149e"]
                            : "pending"}
                        </div>
                        <div className="badge bg-faded-success text-success fs-5">
                          {deal["bb9425b6d811af409ced09a90d314cb90f3058ae"]
                            ? deal[
                                "bb9425b6d811af409ced09a90d314cb90f3058ae"
                              ].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : "pending"}
                        </div>

                        <div className="position-relative mt-3">
                          <div className="fs-sm">Upload Notice</div>
                          <input
                            className="form-control"
                            type="file"
                            id="Y2021-Q1__NOTICE"
                            onChange={(e) => {
                              uploadFiles(e, "2021-Q1__NOTICE");
                            }}
                          />
                          {uploading["2021-Q1__NOTICE"] && (
                            <div className="uploading">
                              <span className="spinner-border spinner-border-sm"></span>{" "}
                              Uploading
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-4 card border-faded-primary m-1">
                      <div className="card-body">
                        <h5 className="card-title">Quarter 2</h5>
                        <div className="p-1 fs-xs text-info">
                          {deal["cf5596d0434f44d7ffba2ebdbf125091f620c413"]
                            ? deal["cf5596d0434f44d7ffba2ebdbf125091f620c413"]
                            : "pending"}
                        </div>
                        <div className="badge bg-faded-success text-success fs-5">
                          {deal["092b06d817f617be39f5b8d2b4f816f2975498cd"]
                            ? deal[
                                "092b06d817f617be39f5b8d2b4f816f2975498cd"
                              ].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : "pending"}
                        </div>

                        <div className="position-relative mt-3">
                          <div className="fs-sm">Upload Notice</div>
                          <input
                            className="form-control"
                            type="file"
                            id="Y2021-Q2__NOTICE"
                            onChange={(e) => {
                              uploadFiles(e, "2021-Q2__NOTICE");
                            }}
                          />
                          {uploading["2021-Q2__NOTICE"] && (
                            <div className="uploading">
                              <span className="spinner-border spinner-border-sm"></span>{" "}
                              Uploading
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-4 card border-faded-primary m-1">
                      <div className="card-body">
                        <h5 className="card-title">Quarter 3</h5>
                        <div className="p-1 fs-xs text-info">
                          {deal["bce0355a07ee3916aec598f0ecae1f4a2bb16930"]
                            ? deal["bce0355a07ee3916aec598f0ecae1f4a2bb16930"]
                            : "pending"}
                        </div>
                        <div className="badge bg-faded-success text-success fs-5">
                          {deal["b4ba54a816adc201dd4255a4ae7692b5837d5d25"]
                            ? deal[
                                "b4ba54a816adc201dd4255a4ae7692b5837d5d25"
                              ].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : "pending"}
                        </div>

                        <div className="position-relative mt-3">
                          <div className="fs-sm">Upload Notice</div>
                          <input
                            className="form-control"
                            type="file"
                            id="Y2021-Q3__NOTICE"
                            onChange={(e) => {
                              uploadFiles(e, "2021-Q3__NOTICE");
                            }}
                          />
                          {uploading["2021-Q3__NOTICE"] && (
                            <div className="uploading">
                              <span className="spinner-border spinner-border-sm"></span>{" "}
                              Uploading
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <Player
                    className="animated-icon"
                    src={noticesImage}
                    background="transparent"
                    speed="0.5"
                    autoplay
                    loop
                  ></Player>
                </>
              )}

              {/* ACTIVITIES */}
              {(pipeline === 3 || pipeline === 7) && (
                <>
                  <h2 className="h5 text-primary mb-4">
                    We are scheduled to do the following:
                  </h2>
                  {activities
                    ? activities.map((activity, index) => (
                        <div
                          className="ps-md-1 ps-lg-0 mt-md-1 py-md-1"
                          key={index}
                        >
                          <div
                            className="toast fade show"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-bs-autohide="false"
                          >
                            <div
                              className={
                                "toast-header " +
                                (activity.done === false ? "" : "bg-secondary")
                              }
                            >
                              <h6 className="fs-sm mb-0 me-auto">
                                <span
                                  className={
                                    "block m-2 " +
                                    (activity.done === false
                                      ? ""
                                      : "text-muted")
                                  }
                                >
                                  {activity.subject}
                                </span>
                              </h6>
                              <small className={"fw-bold text-muted"}>
                                {format(new Date(activity.due_date), "MMMM do")}
                              </small>
                            </div>
                            {activity.done === false ? (
                              <div className="toast-body">
                                {activity.note_clean}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))
                    : ""}
                </>
              )}
            </div>

            {pipeline === 8 && <h3 className="case-closed">Case Closed</h3>}
          </div>
        ) : (
          <>
            <h5 className="case-closed">
              User doesn't exist, Check your email from OSC Consulting and click
              on the link to your dashboard!
            </h5>
          </>
        )}
      </section>

      <footer className="white-gradient"></footer>
    </main>
  );
};

export default Dashboard;
