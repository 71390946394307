import React, { useState } from "react";

const GenerateUrlPage = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    logoURL: "",
    email: "",
    backgroundColor: "",
    emailFromName: "",
    emailFromEmail: "",
    emailReplyTo: "",
    emailSubject: "",
    emailGreetings: "",
    emailDescriptionOne: "",
    emailDescriptionTwo: "",
    downloadText: "",
    emailSignature: "",
  });

  const [generatedUrl, setGeneratedUrl] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const generateUrl = () => {
    const encodedData = Object.entries(formData)
      .map(([key, value]) => {
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join("&");

    const baseUrl = "https://osc.consulting/signature-form";
    const url = `${baseUrl}?${encodedData}`;
    setGeneratedUrl(url);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedUrl);
  };

  return (
    <div className="container">
      <div className="row">
        <h3 className="mt-5">Lead Information</h3>
        <div className="col">
          <div className="form-group">
            <div className="form-group">
              <label htmlFor="firstname">First Name</label>
              <input
                type="text"
                className="form-control"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                placeholder="John"
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <div className="form-group">
              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                className="form-control"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                placeholder="Doe"
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="lead@gmail.com"
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="phone"
                className="form-control"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="(818) 383-2609"
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              className="form-control"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="123 Ventura Blvd., Canoga Park, 91360 CA"
            />
          </div>
        </div>
      </div>
      <h3 className="mt-5">Client Information</h3>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <div className="form-group">
              <label htmlFor="logoURL">Logo URL</label>
              <input
                type="text"
                className="form-control"
                name="logoURL"
                value={formData.logoURL}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="backgroundColor">Background Color</label>
            <input
              type="color"
              className="form-control"
              name="backgroundColor"
              value={formData.backgroundColor}
              onChange={handleInputChange}
              style={{ height: "44px" }}
            />
          </div>
        </div>
      </div>

      <h3 className="mt-5">Email Parameters</h3>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="emailFromName">From Name</label>
            <input
              type="text"
              className="form-control"
              name="emailFromName"
              value={formData.emailFromName}
              onChange={handleInputChange}
              style={{ height: "44px" }}
              placeholder="Jordan Bell"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="emailFromEmail">From Email</label>
            <input
              type="text"
              className="form-control"
              name="emailFromEmail"
              value={formData.emailFromEmail}
              onChange={handleInputChange}
              style={{ height: "44px" }}
              placeholder="client-no-reply@domain.com"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="emailReplyTo">Reply To</label>
            <input
              type="text"
              className="form-control"
              name="emailReplyTo"
              value={formData.emailReplyTo}
              onChange={handleInputChange}
              style={{ height: "44px" }}
              placeholder="reply-to@domain.com"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="emailSubject">Subject</label>
            <input
              type="text"
              className="form-control"
              name="emailSubject"
              value={formData.emailSubject}
              onChange={handleInputChange}
              style={{ height: "44px" }}
              placeholder="Welcome to ..., Here is a copy of your contract, ..."
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="emailGreetings">Greetings</label>
            <input
              type="text"
              className="form-control"
              name="emailGreetings"
              value={formData.emailGreetings}
              onChange={handleInputChange}
              style={{ height: "44px" }}
              placeholder="Dear, Hi, To, ..."
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="downloadText">Download Text</label>
            <input
              type="text"
              className="form-control"
              name="downloadText"
              value={formData.downloadText}
              onChange={handleInputChange}
              style={{ height: "44px" }}
              placeholder="Download Contract, Click Here to Download, etc..."
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="emailDescriptionOne">
              Text before download URL
            </label>
            <textarea
              className="form-control"
              name="emailDescriptionOne"
              value={formData.emailDescriptionOne}
              onChange={handleInputChange}
              style={{ height: "65px" }}
              placeholder="Welcome to ..., below is a copy of your contract with our group, ..."
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="emailDescriptionTwo">Text after download URL</label>
            <textarea
              className="form-control"
              name="emailDescriptionTwo"
              value={formData.emailDescriptionTwo}
              onChange={handleInputChange}
              style={{ height: "65px" }}
              placeholder="Optional text after the download button for their contract"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="emailSignature">Signature</label>
            <textarea
              className="form-control"
              name="emailSignature"
              value={formData.emailSignature}
              onChange={handleInputChange}
              style={{ height: "65px" }}
              placeholder="Sincerely,&#10;Jordan Bell"
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <button className="btn btn-primary" onClick={generateUrl}>
          Generate URL
        </button>
      </div>

      {generatedUrl && (
        <div className="mt-3">
          <label htmlFor="generatedUrl">Generated URL</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              readOnly
              value={generatedUrl}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                onClick={copyToClipboard}
              >
                Copy{" "}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateUrlPage;
