const Header = (props) => {
  return (
    <header className="header navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between">
      <section className="container">
        <div className="row">
          <div className="logo">
            OSC <span className="logo-sub">Consulting</span>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
