import { Route, Link } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

// CSS
import "./App.css";

import Homepage from "./pages/Homepage";
import Calendly from "./pages/Calendly";
import Dashboard from "./pages/Dashboard";
import Restaurant from "./pages/Restaurant";
import Loading from "./pages/Loading";

// Policies
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import PreAssessmentForm from "./pages/PreAssessmentForm";

// Signature
import GenerateUrlPage from "./pages/GenerateURL";
import SignatureForm from "./pages/SignatureForm";

function App(props) {
  return (
    <>
      <main className="page-wrapper">
        <ScrollToTop>
          <Route path="/" exact>
            <Homepage />
          </Route>
          <Route path="/qualify" exact>
            <Homepage />
          </Route>
          <Route path="/index.html" exact>
            <Homepage />
          </Route>
          <Route path="/signature-form" exact>
            <SignatureForm />
          </Route>
          <Route path="/generate-url" exact>
            <GenerateUrlPage />
          </Route>
          <Route path="/calendly">
            <Calendly />
          </Route>
          <Route path="/dashboard" exact>
            <Dashboard
              listFiles={props.listFiles}
              getPerson={props.getPerson}
              getDeal={props.getDeal}
              getActivities={props.getActivities}
              putBasicInformation={props.putBasicInformation}
              putFiles={props.putFiles}
              putTrackingNumber={props.putTrackingNumber}
              updateDeal={props.updateDeal}
              dealUpdated={props.dealUpdated}
            />
          </Route>
          <Route path="/restaurant">
            <Restaurant />
          </Route>
          <Route path="/loading">
            <Loading />
          </Route>
          <Route path="/pre-assessment-form">
            <PreAssessmentForm />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
        </ScrollToTop>
      </main>

      <footer className="footer pt-5 pb-3">
        <div className="container text-center pt-lg-3">
          <p className="fs-sm text-center m-0 fs-xs">
            <Link to="/privacy">Privacy Policy</Link>
            {" • "}
            <Link to="/terms">Terms &amp; Conditions</Link>
          </p>
        </div>
      </footer>
    </>
  );
}

export default App;
