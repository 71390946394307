import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import env from "react-dotenv";

import TagManager from "react-gtm-module";

export const firebaseApp = initializeApp({
  apiKey: env.REACT_APP_APIKEY,
  authDomain: env.REACT_APP_AUTHDOMAIN,
  databaseURL: env.REACT_APP_DATABASEURL,
  projectId: env.REACT_APP_PROJECTID,
  storageBucket: env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_MESSAGINGSENDERID,
  appId: env.REACT_APP_APPID,
  measurementId: env.REACT_APP_MEASUREMENTID,
});

const tagManagerArgs = {
  gtmId: "GTM-WFLT2RG",
  dataLayer: {
    userProject: `OSC`,
  },
};
TagManager.initialize(tagManagerArgs);

export const db = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);

const analytics = getAnalytics(firebaseApp);
const functions = getFunctions();

const listFiles = httpsCallable(functions, "listFiles");
const getPerson = httpsCallable(functions, "getPerson");
const getDeal = httpsCallable(functions, "getDeal");
const getActivities = httpsCallable(functions, "getActivities");
const putBasicInformation = httpsCallable(functions, "putBasicInformation");
const putTrackingNumber = httpsCallable(functions, "putTrackingNumber");
const putFiles = httpsCallable(functions, "putFiles");
const updateDeal = httpsCallable(functions, "updateDeal");
const dealUpdated = httpsCallable(functions, "dealUpdated");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App
      listFiles={listFiles}
      getPerson={getPerson}
      getDeal={getDeal}
      getActivities={getActivities}
      putBasicInformation={putBasicInformation}
      putFiles={putFiles}
      putTrackingNumber={putTrackingNumber}
      updateDeal={updateDeal}
      dealUpdated={dealUpdated}
      TagManager={TagManager}
    />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
