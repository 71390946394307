import { useEffect, useState } from "react";
import env from "react-dotenv";
import axios from "axios";

const TrashMail = ({ email, setEmailValidity }) => {
  const [validity, setValidity] = useState(null);
  const [emailDomains, setEmailDomains] = useState([
    "mailnesia.com",
    "zwoho.com",
    "eoopy.com",
    "cuoly.com",
    "bptfp.net",
    "trash-mail.com",
    "you-spam.com",
    "re-gister.com",
    "fake-box.com",
    "trash-me.com",
    "opentrash.com",
    "bushdown.com",
    "fxseller.com",
    "aceadd.com",
    "eposta.buzz",
    "email.edu.pl",
    "lite14.us",
    "my10minutemail.com",
    "trashmail.fr",
    "trashmail.se",
    "mytrashmailer.com",
    "trashmail.ws",
    "freeinbox.cyou",
    "trashmail.org",
    "thichanthit.com",
    "trythe.net",
    "mentonit.net",
    "tovinit.com",
    "vintomaper.com",
    "biyac.com",
    "mac-24.com",
    "cloud-mail.top",
    "popcornfarm7.com",
    "the23app.com",
    "crepeau12.com",
    "happy-new-year.top",
    "greencafe24.com",
    "coffeetimer24.com",
    "privacy-mail.top",
    "thejoker5.com",
    "appzily.com",
    "bestparadize.com",
    "just4fun.me",
    "mailpoof.com",
    "jriversm.com",
    "sharklasers.com",
    "guerrillamail.info",
    "grr.la",
    "guerrillamail.biz",
    "guerrillamail.com",
    "guerrillamail.de",
    "guerrillamail.net",
    "guerrillamail.org",
    "guerrillamailblock.com",
    "pokemail.net",
    "spam4.me",
    "altmails.com",
    "zooape.net",
    "xeoty.com",
    "sxbta.com",
    "tahyu.com",
    "kucix.com",
    "nafxo.com",
    "bylup.com",
    "fuluj.com",
    "hexud.com",
    "deypo.com",
    "cikuh.com",
    "klepf.com",
    "juzab.com",
    "mailto.plus",
    "fexpost.com",
    "fexbox.org",
    "fexbox.ru",
    "mailbox.in.ua",
    "rover.info",
    "inpwa.com",
    "intopwa.com",
    "tofeat.com",
    "chitthi.in",
    "yevme.com",
    "getnada.com",
    "abyssmail.com",
    "boximail.com",
    "clrmail.com",
    "dropjar.com",
    "getairmail.com",
    "givmail.com",
    "inboxbear.com",
    "robot-mail.com",
    "tafmail.com",
    "vomoto.com",
    "zetmail.com",
    "rlooa.com",
    "monsait.com",
    "archanylibrary.site",
    "lsadinl.com",
    "furnitt.com",
    "jobstoknow.com",
    "lovebite.net",
    "lsnttttw.com",
    "plussmail.com",
    "mobitiveisao.com",
    "gmailya.com",
    "nolteot.com",
    "omdiaco.com",
    "untract.com",
    "halumail.com",
    "spamfellas.com",
    "chewydonut.com",
    "sociallymediocre.com",
    "spamsandwich.com",
    "realquickemail.com",
    "pizzajunk.com",
    "whaaaaaaaaaat.com",
    "hypenated-domain.com",
    "thespamfather.com",
    "silenceofthespam.com",
    "itsjiff.com",
    "snakebutt.com",
    "dgzlweb.com",
    "tempinbox.xyz",
    "grabmail.club",
    "bitcoinportal.com",
    "betaalverzoek.cyou",
    "notvn.com",
    "24hinbox.com",
    "gmailni.com",
    "dyoeii.com",
    "nonise.com",
    "gmailya.com",
    "mamaomka.com",
    "gmailwe.com",
    "aenikaufa.com",
    "jimong.com",
    "homnitachi.com",
    "hotmail.red",
    "eluvit.com",
    "johonmasalalu.com",
    "archanybook.site",
    "emailnax.com",
    "zhcne.com",
    "glubex.com",
    "dfg6.kozow.com",
    "fedix.space",
    "uacro.com",
    "mailed.ro",
    "fls4.gleeze.com",
    "sweepstakesforme.com",
    "kusrc.com",
    "kmdt.cm",
    "o.opendns.ro",
    "i.xcode.ro",
    "laoho.com",
    "cetpass.com",
    "kusrc.com",
    "barryogorman.com",
    "o.spamtrap.ro",
    "hezll.com",
    "psles.com",
    "rkomo.com",
    "tempr.email ",
    "discard.email ",
    "discardmail.com ",
    "discardmail.de",
    "spambog.com ",
    "spambog.de",
    "spambog.ru ",
    "0815.ru ",
    "knol-power.nl ",
    "freundin.ru ",
    "smashmail.de",
    "s0ny.net",
    "pecinan.net",
    "budaya-tionghoa.com",
    "lajoska.pe.hu ",
    "1mail.x24hr.com ",
    "from.onmypc.info",
    "now.mefound.com ",
    "mowgli.jungleheart.com",
    "pecinan.org",
    "budayationghoa.com ",
    "CR.cloudns.asia",
    "TLS.cloudns.asia ",
    "MSFT.cloudns.asia",
    "B.cr.cloUdnS.asia ",
    "ssl.tls.cloudns.ASIA ",
    "sweetxxx.de",
    "DVD.dns-cloud.net ",
    "DVD.dnsabr.com",
    "BD.dns-cloud.net ",
    "YX.dns-cloud.net",
    "SHIT.dns-cloud.net",
    "SHIT.dnsabr.com",
    "eu.dns-cloud.net",
    "eu.dnsabr.com ",
    "asia.dnsabr.com ",
    "8.dnsabr.com ",
    "pw.8.dnsabr.com",
    "mm.8.dnsabr.com",
    "23.8.dnsabr.com ",
    "pecinan.com ",
    "pw.epac.to ",
    "postheo.de",
    "sexy.camdvr.org",
    "888.dnS-clouD.NET",
    "adult-work.info ",
    "trap-mail.de",
    "m.cloudns.cl",
    "you.has.dating",
    "t.woeishyang.com ",
    "blackturtle.xyz ",
    "badlion.co.uk",
    "fouadps.cf ",
    "fshare.ootech.vn",
    "pflege-schoene-haut.de",
    "corona.is.bullsht.dedyn.io",
    "smack.email ",
    "mrgamin.ml",
    "mrgamin.gq",
    "mrgamin.cf",
    "tempmail.wizardmail.tech",
    "mail.mrgamin.ml",
    "kaaaxcreators.tk",
    "mail.kaaaxcreators.tk ",
    "32core.live ",
    "tokyoto.site ",
    "hidemyass.fun ",
    "solpatu.space ",
    "tempxmail.info",
    "mails.v2-ray.net",
    "virtualdepot.store ",
    "127.life",
    "wc.pisskegel.de",
    "panarabanesthesia2021.live ",
    "mail.a1.wtf",
    "discardmail.ninja",
    "mail.lgbtiqa.xyz",
    "tempmail.digital",
    "rcedu.team",
    "discardmail.live",
    "nccedu.team",
    "nccedu.media",
    "discardmail.computer",
    "Disposable-e.ml",
    "streamboost.xyz ",
    "taiwanccedu.studio",
    "students.taiwanccedu.studio",
    "students.rcedu.team",
    "so4ever.codes",
    "okmail.p-e.kr",
    "geekpro.org ",
    "dristypat.com ",
    "dfgdft.ml",
    "sus.ninja ",
    "gettempmail.com",
    "zooaid.org",
    "aalone.xyz",
    "no-one.cyou",
    "no1but.ico",
    "none.cyou",
    "noopmail.org",
    "temmail.xyz",
    "mailglobe.club",
    "mailglobe.org",
    "marmaryta.club",
    "marmaryta.com",
    "marmaryta.email",
    "marmaryta.space",
    "ndeooo.club",
    "ndeooo.com",
    "ndeooo.xyz",
    "bombamail.icu",
    "esiix.com",
    "wwjmp.com",
    "mblungsungi.com",
    "freeallapp.com",
    "halumail.com",
    "zane.pro",
    "igfnicc.com",
    "cudimex.com",
    "bacharg.com",
    "hansgu.com",
    "impactspeaks.com",
    "hotmail.red",
    "monsait.com",
    "emtsua.com",
    "setyamail.me",
    "teeoli.com",
    "pseyusv.com",
    "antawii.com",
    "ecallen.com",
    "ettatct.com",
    "khoantuta.com",
    "gmailya.com",
    "nalsdg.com",
    "typery.com",
    "gmailvn.net",
    "cashbackr.com",
    "xteammail.com",
    "pianoxltd.com",
    "qm1717.com",
    "3kk43.com",
    "eiibps.com",
    "plussmail.com",
    "tempmail.co",
    "fmail10.de",
    "smartinbox.online",
    "yahoo-emails.online",
    "outlook-mails.site",
    "uspmail.com",
    "techdf.com",
    "dyyar.com",
    "omeea.com",
    "20minutemail.it",
    "mzico.com",
    "cdfaq.com",
    "xcoxc.com",
    "jeoce.com",
  ]);

  const checkEmailValidity = (email) => {
    const emailDomain = email.split("@")[1];

    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{3,}$/.test(email)) {
      axios
        .get(
          `https://emailvalidation.abstractapi.com/v1/?api_key=${env.REACT_APP_ABSTRACT_KEY_EMAIL}&email=${email}`
        )
        .then((response) => {
          // console.log(email, response.data.quality_score, response.data);
          if (response.data.quality_score >= 0.7) {
            if (emailDomains.includes(emailDomain) === false) {
              // console.log("email is valid");
              setValidity(true);
              setEmailValidity(true);
            } else {
              // console.log("email is trash");
              setValidity(false);
              setEmailValidity(false);
            }
          } else {
            // console.log("email has a low score");
            setValidity(false);
            setEmailValidity(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (emailDomains.includes(emailDomain) === false) {
            setValidity(true);
            setEmailValidity(true);
          } else {
            setValidity(false);
            setEmailValidity(false);
          }
        });
    }
  };

  useEffect(() => {
    checkEmailValidity(email);
  }, [email]);
};

export default TrashMail;
